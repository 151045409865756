import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CurrentUser } from './CurrentUser';
import { RootState } from '../store';
import { SocketServiceContext } from './SocketServiceContext';
import { ClientUserOnlineEvent } from './UserEvent';
import { ClientUsersUpdateEvent } from './UsersListEvent';
import { UserRole } from '../constants/roles';

const UPDATE_ALL_USERS_ONLINE_INTERVAL = 15000;
const UPDATE_CURRENT_USER_ONLINE_INTERVAL = 5000;
const DOCUMENT_VISIBILITY_STATE = 'visible';

export type CurrentUserInterface = {
  canModerateMessages(): boolean;
  getUserId(): number;
  isBlocked(): boolean;
  isListener(): boolean;
  getMentionedUsers(): string[] | undefined;
};

export const CurrentUserContext = React.createContext<CurrentUserInterface | null>(null);

let pingInterval: NodeJS.Timer;
let updateOnlineStatusInterval: NodeJS.Timer;

const CurrentUserProvider: React.FC<React.ReactNode> = ({ children }) => {
  const user = useSelector((state: RootState) => state.settings.user);
  const isChatLoading = useSelector((state: RootState) => state.settings.isChatLoading);
  const socketService = useContext(SocketServiceContext);

  if (!pingInterval) {
    pingInterval = setInterval(() => {
      if (document.visibilityState === DOCUMENT_VISIBILITY_STATE) {
        socketService?.emit(new ClientUserOnlineEvent());
      }
    }, UPDATE_CURRENT_USER_ONLINE_INTERVAL);
  }

  if (user.role === UserRole.ADMIN || user.role === UserRole.AUTHOR) {
    if (!isChatLoading) {
      socketService?.emit(new ClientUsersUpdateEvent());
    }
    if (!updateOnlineStatusInterval) {
      updateOnlineStatusInterval = setInterval(() => {
        socketService?.emit(new ClientUsersUpdateEvent());
      }, UPDATE_ALL_USERS_ONLINE_INTERVAL);
    }
  }

  return <CurrentUserContext.Provider value={new CurrentUser(user)}>{children}</CurrentUserContext.Provider>;
};

export default CurrentUserProvider;
