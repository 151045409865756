import { AppDispatch } from '../store';
import { updateUser } from '../features/users/users.slice';

import { NAMESPACE, ServerUserEvent } from './UserEvent';
import { ServerEvent, EventSubscriberInterface } from './EventSubscriber';
import { Notify } from '../components/Notify';

export default class UserEventSubscriber implements EventSubscriberInterface {
  eventPrefix: string = NAMESPACE;

  private dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  getSubscribedEvents(): ServerEvent[] {
    return [
      { name: ServerUserEvent.BLOCKED, handler: this.onUserBlocked } as ServerEvent,
      { name: ServerUserEvent.UNBLOCKED, handler: this.onUserUnblocked } as ServerEvent,
      { name: ServerUserEvent.ERROR, handler: this.onError } as ServerEvent,
    ];
  }

  onUserBlocked(blockedId: number): void {
    this.dispatch(updateUser({ _id: blockedId, isBlocked: true }));
  }

  onUserUnblocked(blockedId: number): void {
    this.dispatch(updateUser({ _id: blockedId, isBlocked: false }));
  }

  onError(blockedMessage: string): void {
    Notify.error(blockedMessage);
  }
}
