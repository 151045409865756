import React, { ReactElement } from 'react';

import Paw from '../assets/img/components/paw.svg';

export const CuratorExit: React.FC = (): ReactElement => {
  return (
    <div className="chat-overlay chat-overlay--error">
      <Paw />
      <h1>Вы вышли из чата</h1>
    </div>
  );
};
