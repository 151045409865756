import React from 'react';

import CloseIcon from '../../assets/img/components/icon-close--octicon.svg';
import Tutor from '../../assets/img/components/tutor-face.svg';
import Keks from '../../assets/img/components/keks.svg';

interface Props {
  name: unknown /* Чтобы не усложнять, а вообще-то 'curator' | 'fullscreen' */;
}
export const Modal: React.FC<Props> = (props: Props) => {
  const { name } = props;
  return (
    <div className={`modal modal--${name}`} role="dialog">
      <div className="modal__inner">
        {name !== 'curator' && name !== 'fullscreen' ? (
          <button className="modal__close" type="button" title="Закрыть">
            <CloseIcon />
          </button>
        ) : (
          ''
        )}
        {(name === 'curator' && (
          <div className="modal__wrapper">
            <Tutor />
            <div className="text-container">
              <h2>Куратор готов прийти на&nbsp;помощь, но...</h2>
              <p>Обратите внимание, что куратор&nbsp;— это не&nbsp;проверяющий наставник.</p>
              <p>
                У&nbsp;вас появились вопросы к&nbsp;проверяющему? Задайте их&nbsp;в&nbsp;чате. Наставник получит
                уведомление и&nbsp;ответит на&nbsp;них, как только у&nbsp;него появится свободное время.
              </p>
              <p>
                Если вы&nbsp;не&nbsp;согласны с&nbsp;мнением проверяющего наставника или у&nbsp;вас есть вопросы
                по&nbsp;организационной стороне курса, то&nbsp;позовите куратора, он&nbsp;поможет вам.
              </p>
              <p>Что делаем дальше?</p>
            </div>
            <div className="modal__buttons">
              <button className="modal__button button">Вызываем куратора</button>
              <button className="modal__button button button--orange">Не&nbsp;вызываем куратора</button>
            </div>
          </div>
        )) ||
          (name === 'fullscreen' && (
            <div className="modal__wrapper">
              <Keks />
              <div className="text-container">
                <h2>Вы&nbsp;переходите в&nbsp;полноэкранный режим</h2>
                <p>
                  Чтобы выйти из&nbsp;него, нажмите <code>Esc</code>.
                </p>
              </div>
              <div className="modal__buttons">
                <button className="modal__button button">Понятно</button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
