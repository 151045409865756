import React, { ReactElement, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ChevronDownIcon } from '@primer/octicons-react';
import { RootState } from '../store';
import { setFollowBottom, changeArrowShowingStatus } from '../features/settings/settings.slice';
import PawSVG from '../assets/img/components/paw.svg';
import clsx from 'clsx';

const ScrollParams = {
  HIDDEN_ZONE: 150,
};

interface Props {
  showButton?: boolean;
  follow?: boolean;
  class?: string;
  children: React.ReactNode;
}

export const Scrollable = (props: Props): ReactElement => {
  const { follow } = props;
  const dispatch = useDispatch();
  const followBottom = useSelector((state: RootState) => state.settings.followBottom);
  const isArrowShow = useSelector((state: RootState) => state.settings.isArrowShow);

  const wrapper = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);

  const handleScroll = (): void => {
    if (wrapper.current && content.current) {
      const wrapperHeight = wrapper.current.getBoundingClientRect().height;
      const contentRect = content.current.getBoundingClientRect();
      const contentHeight = contentRect.height;
      const contentScroll = contentRect.y;

      if (follow) {
        const followBottomState = contentHeight - wrapperHeight + contentScroll < 50;
        if (followBottom != followBottomState) {
          dispatch(setFollowBottom(followBottomState));
        }
      }
      const showArrowState = contentHeight - wrapperHeight + contentScroll >= ScrollParams.HIDDEN_ZONE;
      if (isArrowShow != showArrowState) {
        dispatch(changeArrowShowingStatus(showArrowState));
      }
    }
  };

  const scrollToBottom = (): void => {
    if (follow) {
      dispatch(setFollowBottom(true));
    }
  };

  useEffect(() => {
    if (follow && followBottom) {
      if (wrapper.current && content.current) {
        const wrapperHeight = wrapper.current.getBoundingClientRect().height;
        const contentHeight = content.current.getBoundingClientRect().height;
        wrapper.current.scrollTop = contentHeight - wrapperHeight;
      }
    }
  });

  return (
    <div className="scrollable">
      <div className="scrollable__wrapper" onScroll={handleScroll} ref={wrapper}>
        <div className={clsx('scrollable__content', props.class)} ref={content}>
          {props.children}
        </div>
      </div>
      <div className="paw">
        <PawSVG />
      </div>
      {props.showButton && (
        <button className={clsx('scrollable__button', !isArrowShow && 'hidden')} type="button" onClick={scrollToBottom}>
          <ChevronDownIcon />
          Вниз
        </button>
      )}
    </div>
  );
};
