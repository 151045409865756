import { ClientEventInterface } from './EventSubscriber';

export const NAMESPACE = 'users';

export enum ServerUsersListEvent {
  ONLINE_SENT = 'online.sent',
}

export class ClientUsersUpdateEvent implements ClientEventInterface {
  name = 'online.requested';

  getName(): string {
    return `${NAMESPACE}.${this.name}`;
  }

  getPayload(): unknown {
    return;
  }
}
