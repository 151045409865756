import { UserRole } from '../constants/roles';

export interface User {
  _id: number;
  userId: number;
  fullName: string;
  role: UserRole;
  isBlocked: boolean;
  lastVisit?: Date;
  image?: string;
  mentionedUsers?: string[];
  isDeactivated?: boolean;
  isListener?: boolean;
  isOnline?: boolean;
}

export const initialUser: User = {
  _id: 0,
  userId: 0,
  fullName: '',
  image: '',
  role: UserRole.STUDENT,
  isBlocked: false,
  mentionedUsers: [],
  isDeactivated: false,
  isListener: true,
  isOnline: true,
};
