import React, { ReactElement } from 'react';

import { ChevronDownIcon } from '@primer/octicons-react';

// Кнопка скролла вниз
export const ArrowDown = (): ReactElement => {
  return (
    <button className="scrollable__button scrollable__button--active" type="button">
      <ChevronDownIcon />
      Вниз
    </button>
  );
};
