import React from 'react';

// компоненты
import { Message as MessageInterface } from '../models/Message';
import { Scrollable } from '../components/Scrollable';
import { MessageList } from '../components/MessageList';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export const Moderation: React.FC = () => {
  const messages = useSelector((state: RootState) => state.messages.list).filter(
    (msg: MessageInterface) => !msg.deletedAt && msg.needModeration && !msg.deletedAt
  );

  return (
    <div className="chat__container">
      <Scrollable class="chat__list-wrapper chat__list-wrapper--content-start">
        <ul className="chat__list">
          <MessageList messages={messages} />
        </ul>
      </Scrollable>
    </div>
  );
};
