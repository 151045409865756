import React from 'react';
import Twemoji from '../../assets/img/twemoji.svg';

export interface Emoji {
  id: string;
}

export const Emoji: React.FC<Emoji> = (props: Emoji) => {
  const id = props.id.toLowerCase();
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20">
      <use href={`${Twemoji}#${id}`} />
    </svg>
  );
};
