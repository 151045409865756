import React from 'react';
import { User } from '../models/User';
import { TranslatedRole } from '../constants/roles';
import { UserStatusButton } from './moderation/UserStatusButton';
import { UserInfo } from './UserInfo';
import { Badge, Color, Size } from './Badge';

export interface UserElement {
  user: User;
}

export const UserItem: React.FC<UserElement> = (props: UserElement) => {
  const { user } = props;
  return (
    <li className="chat__user-item">
      <UserInfo user={user} showImage={true} />
      <Badge text={user.userId} size={Size.SMALL} color={Color.BLUE} />
      <span className="chat__user-role">
        {TranslatedRole[user.role]}
        {user.isDeactivated && <small className="text-mute">&nbsp;—&nbsp;вышел</small>}
      </span>
      <p className="chat__inspect">
        <UserStatusButton userId={user._id} />
      </p>
    </li>
  );
};
