import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MuteIcon } from '@primer/octicons-react';
import { User } from '../../models/User';
import { SocketServiceContext } from '../../services/SocketServiceContext';
import { ClientUserBlockedEvent, ClientUserUnblockedEvent } from '../../services/UserEvent';
import { RootState } from '../../store';
import clsx from 'clsx';

export interface Props {
  userId: number;
}

export const UserStatusButton: React.FC<Props> = (props: Props) => {
  const { userId } = props;
  const user = useSelector((state: RootState) => state.users).find((user: User) => user._id === userId);
  const socketService = useContext(SocketServiceContext);

  if (!user) {
    return <></>;
  }

  const onBtnClick = () => {
    socketService?.emit(user.isBlocked ? new ClientUserUnblockedEvent(user) : new ClientUserBlockedEvent(user));
  };

  const title = user.isBlocked ? 'Разблокировать' : 'Заблокировать';

  return (
    <button
      className={clsx(
        'chat__inspect-button',
        'chat__inspect-button--mute',
        user.isBlocked && 'chat__inspect-button--active'
      )}
      type="button"
      onClick={onBtnClick}
      title={title}
    >
      <MuteIcon />
      {title}
    </button>
  );
};
