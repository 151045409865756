import { ClientEventInterface } from './EventSubscriber';
import { Message } from '../models/Message';

export const NAMESPACE = 'messages';

export enum ServerMessagesListEvent {
  LOADED = 'loaded',
  ERROR = 'error',
}

export class ClientMessagesUpdateEvent implements ClientEventInterface {
  name = 'requested';

  message: Message;

  constructor(message: Message) {
    this.message = message;
  }

  getName(): string {
    return `${NAMESPACE}.${this.name}`;
  }

  getPayload(): unknown {
    return { lastMessageDate: this.message.createdAt, lastMessageId: this.message._id };
  }
}
