import React from 'react';
import clsx from 'clsx';
import { EmojiIcon } from './EmojiIcon';

type MessageReactionItem = {
  emojiId: string;
  reactionCounter: number;
  isReactionByCurrentUser: boolean;
  onClickCallback: (emojiId: string, byCurrentUser: boolean) => void;
  isDisabled?: boolean;
};

export const MessageReactionItem: React.FC<MessageReactionItem> = (props: MessageReactionItem) => {
  const emojiId = props.emojiId.toLowerCase();
  const onClickCallback = props.onClickCallback;
  return (
    <li className="chat__reactions-item">
      <button
        type="button"
        className={clsx('chat__reactions-button', props.isReactionByCurrentUser && 'chat__reactions-button--active')}
        onClick={() => onClickCallback(emojiId, props.isReactionByCurrentUser)}
        disabled={props.isDisabled}
      >
        <EmojiIcon id={emojiId} />
        <span className="sr-only"></span>
        <span className="chat__reactions-counter">{props.reactionCounter}</span>
      </button>
    </li>
  );
};
