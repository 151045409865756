import React from 'react';
import { Message as MessageInterface } from '../models/Message';
import { isSameUserSendMessage, checkDateGroupMessage } from '../helpers/utils';

// компоненты
import { Message } from './Message';

interface Props {
  messages: MessageInterface[];
}

export const MessageList: React.FC<Props> = (props: Props) => {
  const { messages } = props;

  return (
    <>
      {messages.map((message: MessageInterface, i: number) => (
        <Message
          key={i}
          message={message}
          isSameUser={isSameUserSendMessage(messages, i)}
          dateGroup={checkDateGroupMessage(messages, i)}
        />
      ))}
    </>
  );
};
