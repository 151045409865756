import React, { ReactElement } from 'react';

// компоненты
import { Tabs } from '../components-markup/Tabs';
import { MoreButtons } from '../components-markup/MoreButtons';

export const RulesMarkup = (): ReactElement => {
  const isPublic = true;

  return (
    <div className="chat" aria-label="Чат">
      <header className="chat__header">
        <Tabs {...{ isPublic }} />

        <MoreButtons />
      </header>

      <main className="chat__tab" aria-labelledby="rules">
        <div className="chat__container">
          <div className="scrollable">
            <div className="scrollable__wrapper" id="chat-window">
              <div className="scrollable__content">
                <div className="chat__rules text-container">
                  <h2>Правила чата</h2>
                  <ul>
                    <li>Общение в чате должно быть доброжелательным и уважительным.</li>
                    <li>В чате не должно быть оскорблений, флуда, спама и холиваров.</li>
                    <li>
                      Чтобы задать вопрос преподавателю, поставьте галочку <strong>Задать вопрос</strong>.
                    </li>
                    <li>Все ссылки проходят модерацию и попадают в чат только после одобрения куратора.</li>
                    <li>
                      Если вы нарушите правила, куратор заберёт у вас возможность отправлять сообщения, но вы всё ещё
                      сможете читать чат и смотреть лекцию.
                    </li>
                    <li>Имена кураторов и авторов выделены красным цветом, наставников — синим, ваше имя — зеленым.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
