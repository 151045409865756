import React, { useContext, useEffect, useState } from 'react';
import { Message as MessageInterface } from '../models/Message';

// компоненты
import { Scrollable } from '../components/Scrollable';
import { MessageForm } from '../components/MessageForm';
import { MessageList } from '../components/MessageList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { SocketServiceContext } from '../services/SocketServiceContext';
import { ClientMessagesUpdateEvent } from '../services/MessagesListEvent';
import { changeRequestedMessagesStatus } from '../features/settings/settings.slice';
import { Loader } from '../components/Loader';
import ReactionService from '../services/ReactionContext';
import { ModalReactions } from '../components/reactions/ModalReactions';
import { ModalReactionsBot } from '../components/reactions/ModalReactionsBot';

export const Chat: React.FC = () => {
  const dispatch = useDispatch();
  const socketService = useContext(SocketServiceContext);
  const messages = useSelector((state: RootState) => state.messages.list).filter((msg: MessageInterface) => {
    if (msg.needModeration && msg.editedBody) {
      return true;
    }
    return !msg.needModeration && !msg.deletedAt;
  });
  const isMessagesRequested = useSelector((state: RootState) => state.settings.isMessagesRequested);
  const isMessagesLoaded = useSelector((state: RootState) => state.messages.loaded);
  const firstMsgId = messages[0]?._id;
  const [prevMsgId, setPrevMsgId] = useState('');

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting && entry.target.id === firstMsgId) {
        if (!isMessagesRequested && !isMessagesLoaded) {
          setPrevMsgId(firstMsgId);
          dispatch(changeRequestedMessagesStatus(true));
          socketService?.emit(new ClientMessagesUpdateEvent(messages[0]));
        }
        return;
      }
    },
    {
      root: null,
      threshold: 1,
    }
  );

  useEffect(() => {
    const els = document.querySelectorAll('.chat__item');
    if (isMessagesLoaded) {
      observer.disconnect();
    } else {
      if (els.length > 0) {
        observer.observe(els[0]);
      }
    }
    return () => observer.disconnect();
  }, [messages, isMessagesLoaded]);

  useEffect(() => {
    setTimeout(() => {
      try {
        const firstMsg = document.querySelector(`[id=${CSS.escape(prevMsgId)}]`);
        if (firstMsg) {
          firstMsg.scrollIntoView();
        }
      } catch (e) {
        return e;
      }
    }, 1);
  }, [firstMsgId]);

  return (
    <ReactionService>
      <div className="chat__container">
        <Scrollable showButton={true} follow={true} class="chat__list-wrapper">
          {isMessagesRequested && !isMessagesLoaded && <Loader />}
          <ul className="chat__list">
            <MessageList messages={messages} />
          </ul>
        </Scrollable>
      </div>

      <MessageForm />

      <ModalReactions messages={messages} />
      <ModalReactionsBot />
    </ReactionService>
  );
};
