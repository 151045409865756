import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PublicChatMarkup } from '../markup/screens-markup/PublicChat';
import { PublicChatStreamQuestionsMarkup } from '../markup/screens-markup/PublicChatStreamQuestions';
import { PublicChatStreamMarkup } from '../markup/screens-markup/PublicChatStream';
import { PrivatChatMarkup } from '../markup/screens-markup/PrivatChat';
import { RulesMarkup } from '../markup/screens-markup/Rules';
import { UsersMarkup } from '../markup/screens-markup/Users';
import { ModalMarkup } from '../markup/screens-markup/Modal';
import { QuestionsMarkup } from '../markup/screens-markup/Questions';
import { Error } from '../markup/screens-markup/Error';
import { ToastifyMarkup } from '../markup/screens-markup/Toastify';

import SocketServiceProvider from '../services/SocketServiceContext';

import { App } from './App';
import CurrentUserProvider from '../services/CurrentUserContext';
import { CuratorExit } from '../components/CuratorExit';

export const CURATOR_EXIT_ROUTE = '/exit';

export const Root = (): ReactElement => {
  return (
    <Route>
      <Switch>
        {process.env.MODE === 'development' && (
          <Route path="/markup">
            <Switch>
              <Route path="/markup/privat-chat">
                <PrivatChatMarkup />
              </Route>
              <Route path="/markup/public-chat">
                <PublicChatMarkup />
              </Route>
              <Route path="/markup/public-chat-stream-questions">
                <PublicChatStreamQuestionsMarkup />
              </Route>
              <Route path="/markup/public-chat-stream">
                <PublicChatStreamMarkup />
              </Route>
              <Route path="/markup/rules">
                <RulesMarkup />
              </Route>
              <Route path="/markup/questions">
                <QuestionsMarkup />
              </Route>
              <Route path="/markup/users">
                <UsersMarkup />
              </Route>
              <Route path="/markup/error">
                <Error />
              </Route>
              <Route path="/markup/modal">
                <ModalMarkup />
              </Route>
              <Route path="/markup/toastify">
                <ToastifyMarkup />
              </Route>
            </Switch>
          </Route>
        )}
        <Route>
          <Switch>
            <Route path={CURATOR_EXIT_ROUTE}>
              <CuratorExit />
            </Route>
            <Route path="/">
              <SocketServiceProvider>
                <CurrentUserProvider>
                  <App />
                </CurrentUserProvider>
              </SocketServiceProvider>
            </Route>
          </Switch>
        </Route>
      </Switch>
    </Route>
  );
};
