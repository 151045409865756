import React from 'react';

export interface SeparatorElement {
  unread?: boolean;
  date?: string;
}

export const Separator: React.FC<SeparatorElement> = (props: SeparatorElement) => {
  const { unread, date } = props;
  return (
    <li role="separator" className={`chat__separator separator ${unread ? 'separator--unread' : ''}`}>
      {date ? <span className="separator__date">{date}</span> : ''}
      {unread ? <span className="separator__unread">Новое</span> : ''}
    </li>
  );
};
