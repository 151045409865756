import React from 'react';

import { Modal } from './Modal';
import Keks from '../../assets/img/components/keks.svg';
import { changeActiveTab, changeSelectedQuestionsMode, changeStreamMode } from '../../features/settings/settings.slice';
import { useDispatch } from 'react-redux';
import { Tab } from '../../constants/tabs';

interface Props {
  isChatStreamMode?: boolean;
}

export const ModalFullscreen: React.FC<Props> = (props: Props) => {
  const { isChatStreamMode } = props;
  const dispatch = useDispatch();

  const onFullScreenModeBtnClick = () => {
    dispatch(changeStreamMode(true));
    if (isChatStreamMode) {
      dispatch(changeActiveTab(Tab.CHAT));
    } else {
      dispatch(changeActiveTab(Tab.QUESTIONS));
      dispatch(changeSelectedQuestionsMode(true));
    }
  };

  return (
    <Modal name="fullscreen">
      <div className="modal__wrapper">
        <Keks />
        <div className="text-container">
          <h2>Вы&nbsp;переходите в&nbsp;полноэкранный режим</h2>
          <p>
            Чтобы выйти из&nbsp;него, нажмите <code>Ctrl + Q</code>.
          </p>
        </div>
        <div className="modal__buttons">
          <button className="modal__button button" onClick={onFullScreenModeBtnClick}>
            Понятно
          </button>
        </div>
      </div>
    </Modal>
  );
};
