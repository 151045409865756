import { initialUser, User } from './User';

export interface RoomSettings {
  moderator?: {
    // Есть ли возможность вызвать модератора
    isAvailable?: boolean;
    // Был ли модератор уже вызван
    isCalled?: boolean;
  };
  // Скрытие имён для всех, кроме модераторов
  isAnonymous?: boolean;
  // Вкл/выкл модерации ссылок
  hasModeration?: boolean;
  // Вкл/выкл доступа к лайву
  hasStream?: boolean;
  // Вкл/выкл доступа к вопросам
  hasQuestions?: boolean;
  // Вкл/выкл аватарок
  hasImages?: boolean;
}

export interface Room {
  roomId: string;
  title?: string;
  url?: string;
  creatorId: number;
  users: User[];
  settings: RoomSettings;
  createdAt: Date;
  pinnedMessage?: string;
}

export const initialRoom: Room = {
  roomId: '',
  creatorId: 0,
  users: [initialUser],
  createdAt: new Date(),
  settings: {} as RoomSettings,
};
