import React, { useContext, MouseEvent } from 'react';
import { MuteIcon } from '@primer/octicons-react';
import { User } from '../models/User';
import { UserRole } from '../constants/roles';
import { CurrentUserContext } from '../services/CurrentUserContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import unknownRaccoon from '../assets/img/unknown-raccoon.svg';
import { addMentionedUsers } from '../features/settings/settings.slice';
import clsx from 'clsx';
import { Tab } from '../constants/tabs';

export interface Props {
  user?: User;
  showImage?: boolean;
  isInQuote?: boolean;
}

export const UserInfo: React.FC<Props> = (props: Props) => {
  const { user, showImage, isInQuote } = props;
  const dispatch = useDispatch();
  const room = useSelector((state: RootState) => state.settings.room);
  const activeTab = useSelector((state: RootState) => state.settings.activeTab);
  const users = useSelector((state: RootState) => state.users);

  const currentUser = useContext(CurrentUserContext);
  const currentUserId = currentUser?.getUserId();

  if (!user) {
    return <></>;
  }

  let showBlocked = false;
  if (currentUser?.canModerateMessages() && !isInQuote) {
    showBlocked = users.find((usr) => usr._id === user?._id)?.isBlocked || false;
  }

  let className = '';
  switch (user.role) {
    case UserRole.TUTOR:
      className = 'chat__user--tutor';
      break;
    case UserRole.ADMIN:
    case UserRole.AUTHOR:
      className = 'chat__user--curator';
      break;
  }

  const onUserNameClick = (evt: MouseEvent<HTMLSpanElement>): void => {
    if (user._id !== currentUserId && evt.currentTarget.textContent && currentUserId) {
      dispatch(addMentionedUsers(evt.currentTarget.textContent));
      const textarea = document.querySelector('#new-message') as HTMLTextAreaElement;
      textarea?.focus();
    }
  };

  return (
    <>
      {(showImage || room.settings?.hasImages) && (
        <img
          className="chat__avatar"
          src={user.image ? user.image : unknownRaccoon}
          width="30"
          height="30"
          alt={user.fullName}
        />
      )}
      <span
        onClick={(evt) => onUserNameClick(evt)}
        className={clsx('chat__user', className, currentUserId === user._id && 'chat__user--me')}
      >
        {user.fullName}
      </span>
      {activeTab === Tab.USERS && user.isOnline && (
        <span className="chat__user-online" title="Пользователь в сети">
          <i className="sr-only">Пользователь в сети</i>
        </span>
      )}
      {showBlocked ? (
        <span className="chat__user-status">
          <MuteIcon verticalAlign="middle" />
          Забанен
        </span>
      ) : (
        ''
      )}
    </>
  );
};
