import React from 'react';
import CloseIcon from '../../assets/img/components/icon-close--octicon.svg';
interface Props {
  isMessageQuoted: boolean;
}

import PaperAirplaneIcon from '../../assets/img/components/icon-paper-airplane.svg';

const resizeTextarea = (textarea: HTMLTextAreaElement): void => {
  const offset = textarea.offsetHeight - textarea.clientHeight;
  const currentHeight = textarea.clientHeight + offset;
  textarea.style.height = 'auto';
  const fullHeight = textarea.scrollHeight + offset;
  if (fullHeight !== currentHeight) {
    textarea.style.height = `${fullHeight + 1}px`;
  } else {
    textarea.style.height = `${fullHeight}px`;
  }
};

// Форма сообщения-ответа с цитированием
export const MessageFormSimple: React.FC<Props> = (props: Props) => {
  const { isMessageQuoted } = props;
  const onTextareaKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    const textarea = event.target as HTMLTextAreaElement;

    if (event.key === 'Enter' && event.ctrlKey) {
      resizeTextarea(textarea);
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      textarea.style.height = 'auto';
    }
  };

  const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const textarea = event.target;
    resizeTextarea(textarea);
  };

  return (
    <form className={`chat__form ${isMessageQuoted ? 'chat__form--quoted' : ''}`} action="#">
      {/*  Блок вопроса на который отвечают в данный момент */}
      {isMessageQuoted && (
        <div className="chat__form-header">
          <blockquote className="chat__quote" tabIndex={0}>
            <span className="chat__user">Элеонора Элеоноровна</span>
            <div className="chat__text">
              <p>Вопрос пользователя, на который отвечает админ или лектор</p>
            </div>
          </blockquote>
          <button className="chat__close" type="button" title="Удалить">
            <CloseIcon />
          </button>
        </div>
      )}
      <textarea
        className="field field--text field--full-width"
        rows={1}
        name="new-message"
        id="new-message"
        placeholder="Отправить сообщение"
        onChange={onChangeText}
        onKeyDown={onTextareaKeyDown}
      ></textarea>
      <div className="chat__group-bottom">
        <p className="chat__character-counter  chat__character-counter--warning" aria-atomic="true" aria-live="polite">
          -300
          <span className="sr-only" aria-atomic="true" aria-live="polite">
            Вы превысили ограничение длины. Лишних симовлов — 300
          </span>
        </p>
        <button className="chat__button-submit" type="submit" title="Отправить" disabled>
          Отправить
          <PaperAirplaneIcon />
        </button>
      </div>
    </form>
  );
};
