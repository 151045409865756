import React, { useState } from 'react';

import { Message } from '../models/Message';

export type EditMessageServiceInterface = {
  editedMessage: Message | null;
  setEditedMessage: (editedMessage: Message) => void;
  clearEditedMessage: () => void;
};

export const EditMessageServiceContext = React.createContext<EditMessageServiceInterface | null>(null);

const EditMessageService: React.FC<React.ReactNode> = ({ children }) => {
  const [editedMessage, setEditedMessage] = useState<Message | null>(null);

  const clearEditedMessage = (): void => {
    setEditedMessage(null);
  };

  return (
    <EditMessageServiceContext.Provider value={{ editedMessage, setEditedMessage, clearEditedMessage }}>
      {children}
    </EditMessageServiceContext.Provider>
  );
};

export default EditMessageService;
