import React, { ReactElement } from 'react';

// компоненты
import { Tabs } from '../components-markup/Tabs';
import { MoreButtons } from '../components-markup/MoreButtons';
import { User } from '../components-markup/User';

export const UsersMarkup = (): ReactElement => {
  const isPublic = true;

  return (
    <div className="chat" aria-label="Чат">
      <header className="chat__header">
        <Tabs {...{ isPublic }} />

        <MoreButtons />
      </header>

      <main className="chat__tab" aria-labelledby="users">
        <form className="chat__search" action="#">
          <input className="field field--text field--full-width" type="text" placeholder="Поиск пользователя…" />
          <label className="chat__search-toggle toggle">
            <input className="toggle__input" type="checkbox" />
            <span className="toggle__text">Только онлайн</span>
          </label>
        </form>

        <div className="chat__container">
          <div className="scrollable">
            <div className="scrollable__wrapper">
              <div className="scrollable__content">
                <ul className="chat__user-list">
                  <User isBanned={false} name="Правдорубов" id="12" role="Куратор" isOnline={true} />
                  <User isBanned={true} name="Джуновский" id="12132" role="Студент" />
                  <User isBanned={false} name="Дельфинова" id="1" role="Админ" />
                  <User
                    isBanned={false}
                    name="ДжуновскийДжуновскийДжуновскийДжуновскийДжуновскийДжуновскийДжуновскийДжуновскийДжуновскийДжуновскийДжуновскийДжуновскийДжуновскийуновскийДжуновскийДжуновскиуновскийДжуновскийДжуновски"
                    id="12132"
                    role="Студент"
                  />
                  <User isBanned={false} name="." id="12132" role="Студент" />
                  <User isBanned={false} name="Кто-то неведомый" id="12132" role="" />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
