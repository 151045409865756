export type Emoji = {
  id: string;
  description: string;
};

export const emojis: Emoji[] = [
  {
    id: '1F525',
    description: 'fire',
  },
  {
    id: '1F914',
    description: 'thinking-face',
  },
  {
    id: '1F917',
    description: 'smiling-face-with-open-hands',
  },
  {
    id: '1F44D',
    description: 'thumbs-up',
  },
  {
    id: '1F44C',
    description: 'OK-hand',
  },
  {
    id: '2705',
    description: 'check-mark-button',
  },
  {
    id: '2795',
    description: 'plus',
  },
  {
    id: '1F64F',
    description: 'folded-hands',
  },
  {
    id: '1F4AA',
    description: 'flexed-biceps',
  },
  {
    id: '2764',
    description: 'red-heart',
  },
  {
    id: '1F60A',
    description: 'smiling-face-with-smiling-eyes',
  },
  {
    id: '1F642',
    description: 'slightly-smiling-face',
  },
  {
    id: '1F602',
    description: 'face-with-tears-of-joy',
  },
  {
    id: '1F923',
    description: 'rolling-on-the-floor-laughing',
  },
  {
    id: '1F606',
    description: 'grinning-squinting-face',
  },
  {
    id: '1F92D',
    description: 'face-with-hand-over-mouth',
  },
  {
    id: '1F601',
    description: 'beaming-face-with-smiling-eyes',
  },
  {
    id: '1F92A',
    description: 'zany-face',
  },
  {
    id: '1F61C',
    description: 'winking-face-with-tongue',
  },
  {
    id: '1F609',
    description: 'winking-face',
  },
  {
    id: '1f618',
    description: '',
  },
  {
    id: '1F618',
    description: 'face-blowing-a-kiss',
  },
  {
    id: '1f60d',
    description: 'smiling-face-with-heart-eyes',
  },
  {
    id: '1f929',
    description: 'star-struck',
  },
  {
    id: '1f643',
    description: 'upside-down-face',
  },
  {
    id: '1f60c',
    description: 'relieved-face',
  },
  {
    id: '1f60f',
    description: 'smirking-face',
  },
  {
    id: '1f607',
    description: 'smiling-face-with-halo',
  },
  {
    id: '1f924',
    description: 'drooling-face',
  },
  {
    id: '1f973',
    description: 'partying-face',
  },
  {
    id: '1f913',
    description: 'nerd-face',
  },
  {
    id: '1f60e',
    description: 'smiling-face-with-sunglasses',
  },
  {
    id: '1f605',
    description: 'grinning-face-with-sweat',
  },
  {
    id: '1f972',
    description: 'smiling-face-with-tear',
  },
  {
    id: '1f611',
    description: 'expressionless-face',
  },
  {
    id: '1f612',
    description: 'unamused-face',
  },
  {
    id: '1f644',
    description: 'face-with-rolling-eyes',
  },
  {
    id: '1f633',
    description: 'flushed-face',
  },
  {
    id: '1f635-200d-1f4ab',
    description: 'face-with-spiral-eyes',
  },
  {
    id: '1f61e',
    description: 'disappointed-face',
  },
  {
    id: '1f614',
    description: 'pensive-face',
  },
  {
    id: '1f97a',
    description: 'pleading-face',
  },
  {
    id: '1f615',
    description: 'confused-face',
  },
  {
    id: '2639',
    description: 'frowning-face',
  },
  {
    id: '1f629',
    description: 'weary-face',
  },
  {
    id: '1f622',
    description: 'crying-face',
  },
  {
    id: '1f62d',
    description: 'loudly-crying-face',
  },
  {
    id: '1f62e',
    description: 'face-with-open -mouth',
  },
  {
    id: '1f631',
    description: 'face-screaming-in-fear',
  },
  {
    id: '1f92f',
    description: 'exploding-head',
  },
  {
    id: '1f62c',
    description: 'grimacing-face',
  },
  {
    id: '1f620',
    description: 'angry-face',
  },
  {
    id: '1f92c',
    description: 'face-with-symbols-on-mouth',
  },
  {
    id: '1f608',
    description: 'smiling-face-with-horns',
  },
  {
    id: '1f975',
    description: 'hot-face',
  },
  {
    id: '1f976',
    description: 'cold-face',
  },
  {
    id: '1F926',
    description: 'person-facepalming',
  },
  {
    id: '1f937',
    description: 'person-shrugging',
  },
  {
    id: '1f64b',
    description: '-person-raising-hand',
  },
  {
    id: '1f9d1-200d-1f4bb',
    description: 'technologist',
  },
  {
    id: '1f63a',
    description: 'grinning-cat',
  },
  {
    id: '1f63e',
    description: 'pouting-cat',
  },
  {
    id: '1f451',
    description: 'crown',
  },
  {
    id: '2728',
    description: 'sparkles',
  },
  {
    id: '1f389',
    description: 'party-popper',
  },
  {
    id: '1f381',
    description: 'wrapped-gift',
  },
  {
    id: '1f388',
    description: 'balloon',
  },
  {
    id: '1f490',
    description: 'bouquet',
  },
  {
    id: '1f338',
    description: 'cherry-blossom',
  },
  {
    id: '1f494',
    description: 'broken-heart',
  },
  {
    id: '1f44f',
    description: 'clapping-hands',
  },
  {
    id: '1f44e',
    description: 'thumbs-down',
  },
  {
    id: '270c',
    description: 'victory-hand',
  },
  {
    id: '1f449',
    description: 'backhand-index-pointing-right',
  },
  {
    id: '1f448',
    description: 'backhand-index-pointing-left',
  },
  {
    id: '1f91e',
    description: 'crossed-fingers',
  },
  {
    id: '1f447',
    description: 'backhand-index-pointing-down',
  },
  {
    id: '261d',
    description: 'index-pointing-up',
  },
  {
    id: '1f918',
    description: 'sign-of-hhe-horns',
  },
  {
    id: '1f64c',
    description: 'raising-hands',
  },
  {
    id: '1f91d',
    description: 'handshake',
  },
  {
    id: '23F3',
    description: 'hourglass-not-done',
  },
  {
    id: '1f440',
    description: 'eyes',
  },
  {
    id: '2796',
    description: 'minus',
  },
  {
    id: '2757',
    description: 'red-exclamation-mark',
  },
  {
    id: '1f6ab',
    description: 'prohibited',
  },
  {
    id: '1f4af',
    description: 'hundred-points',
  },
  {
    id: '1f355',
    description: 'pizza',
  },
  {
    id: '2615',
    description: 'hot-beverage',
  },
  {
    id: '1f37b',
    description: 'clinking-beer-mugs',
  },
  {
    id: '1f340',
    description: 'four-leaf-clover',
  },
  {
    id: '1f4a5',
    description: 'collision',
  },
  {
    id: '1f3c6',
    description: 'trophy',
  },
  {
    id: '1f4b0',
    description: 'money-bag',
  },
  {
    id: '1f31e',
    description: 'sun-with-face',
  },
  {
    id: '1f984',
    description: 'unicorn',
  },
  {
    id: '1f480',
    description: 'skull',
  },
  {
    id: '1f648',
    description: 'see-no-evil-monkey',
  },
  {
    id: '1f3c3',
    description: 'person-running',
  },
  {
    id: '1f483',
    description: 'woman-dancing',
  },
];
