import React, { FormEvent, useContext, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import PaperAirplaneIcon from '../assets/img/components/icon-paper-airplane.svg';
import CloseIcon from '../assets/img/components/icon-close--octicon.svg';
import { Tab } from '../constants/tabs';
import { QuoteServiceContext } from '../services/QuoteService';
import { CreateMessageContext } from '../services/CreateMessageService';
import { SocketServiceContext } from '../services/SocketServiceContext';
import { ClientMessageEditedEvent, ClientMessageSentEvent } from '../services/MessageEvent';
import { ClientMessage, EditedMessage } from '../models/Message';
import { RootState } from '../store';
import { CurrentUserContext } from '../services/CurrentUserContext';

import { setFollowBottom, removeMentionedUsers } from '../features/settings/settings.slice';
import { EditMessageServiceContext } from '../services/EditMessageService';
import { Notify } from './Notify';

const CHARACTER_LIMIT = 500;
const CHARACTERS_UNDER_LIMIT_TO_SHOW_COUNTER = 50;
const CHARACTERS_OVER_LIMIT_TO_SHOW_WIDER_COUNTER = 100;

export const MessageForm: React.FC = () => {
  const dispatch = useDispatch();

  const isFirstRender = useRef(true);
  const quoteService = useContext(QuoteServiceContext);
  const createMessageService = useContext(CreateMessageContext);
  const editedMessageService = useContext(EditMessageServiceContext);
  const socketService = useContext(SocketServiceContext);
  const [message, setMessage] = useState(createMessageService?.message.content || '');
  const [isQuestion, setQuestion] = useState(createMessageService?.message.isQuestion || false);
  const [showCharactersCounter, setCharactersCounterShowing] = useState(false);
  const [isCharacterLimitExceeded, setCharacterLimitExceeded] = useState(false);

  const { activeTab, room } = useSelector((state: RootState) => {
    return {
      activeTab: state.settings.activeTab,
      room: state.settings.room,
    };
  });
  const currentUser = useContext(CurrentUserContext);
  const isFormDisabled = currentUser?.isBlocked() || currentUser?.isListener() || false;

  const resizeTextarea = () => {
    const textarea = document.querySelector('#new-message') as HTMLTextAreaElement;
    if (textarea) {
      const offset = textarea.offsetHeight - textarea.clientHeight;
      const currentHeight = textarea.clientHeight + offset;
      textarea.style.height = 'auto';
      const fullHeight = textarea.scrollHeight + offset;
      if (fullHeight !== currentHeight) {
        textarea.style.height = `${fullHeight + 1}px`;
      } else {
        textarea.style.height = `${fullHeight}px`;
      }
    }
  };

  const onSubmitBtnClick = () => {
    if (message.length > CHARACTER_LIMIT) {
      Notify.error(`Количество символов в сообщении не должно превышать ${CHARACTER_LIMIT} символов.`);
      return;
    }
    if (message.trim().length) {
      if (editedMessageService?.editedMessage) {
        socketService?.emit(
          new ClientMessageEditedEvent({
            id: editedMessageService?.editedMessage?._id,
            body: message,
          } as EditedMessage)
        );
      } else {
        socketService?.emit(
          new ClientMessageSentEvent({
            body: message,
            isQuestion: activeTab === Tab.CHAT && room.settings?.hasQuestions ? isQuestion : undefined,
            quoteMessageId: quoteService?.quote?._id,
          } as ClientMessage)
        );
      }

      if (
        message
          .toLowerCase()
          .replace(/[^0-9А-ЯЁа-яё]+/gi, ' ')
          .trim() === 'кекс привет'
      ) {
        const paw = document.querySelector('.paw');
        const animationIsPlaying = paw?.className.includes('animation-is-playing');

        if (paw && !animationIsPlaying) {
          paw.classList.add('animation-is-playing');
          paw.classList.add('paw--shown');
          setTimeout(() => {
            paw.classList.remove('paw--shown');
            setTimeout(() => {
              paw.classList.remove('animation-is-playing');
            }, 1500);
          }, 2500);
        }
      }

      quoteService?.clearQuote();
      editedMessageService?.clearEditedMessage();
      setQuestion(false);
    }
    setMessage('');
    setCharactersCounterShowing(false);
    setCharacterLimitExceeded(false);
    dispatch(setFollowBottom(true));
    dispatch(removeMentionedUsers());
  };

  const onTextareaChange = (evt: FormEvent) => {
    const target = evt.target as HTMLTextAreaElement;
    setMessage(target.value);
    if (CHARACTER_LIMIT - target.value.length <= CHARACTERS_UNDER_LIMIT_TO_SHOW_COUNTER) {
      setCharactersCounterShowing(true);
    } else {
      setCharactersCounterShowing(false);
    }
    if (target.value.length > CHARACTER_LIMIT) {
      setCharacterLimitExceeded(true);
    } else {
      setCharacterLimitExceeded(false);
    }
  };

  const onQuestionChange = (evt: FormEvent) => {
    const target = evt.target as HTMLInputElement;
    setQuestion(target.checked);
  };

  useEffect(() => {
    const handler = (evt: KeyboardEvent) => {
      if (
        (evt.key === 'Enter' && !evt.shiftKey) ||
        (evt.key === 'Enter' && evt.ctrlKey) ||
        (evt.key === 'Enter' && evt.metaKey)
      ) {
        evt.preventDefault();
        onSubmitBtnClick();
      }
    };
    document.addEventListener('keydown', handler);

    return () => document.removeEventListener('keydown', handler);
  });

  const onClearQuoteBtnClick = () => {
    quoteService?.clearQuote();
  };

  const onClearEditedMessageBtnClick = () => {
    editedMessageService?.clearEditedMessage();
  };

  useEffect(() => {
    const mentionedUsers = currentUser?.getMentionedUsers();
    mentionedUsers?.forEach((usr) => {
      setMessage(`${usr}, ${message}`);
    });
  }, [currentUser]);

  useEffect(() => {
    if (!isFirstRender.current) {
      const editedMessage = editedMessageService?.editedMessage?.bodyMarkdown?.trim() || '';
      setMessage(editedMessage);
      const textArea = document.querySelector('.field--text') as HTMLTextAreaElement;
      textArea?.focus();
    } else {
      isFirstRender.current = false;
    }
  }, [editedMessageService?.editedMessage]);

  useEffect(() => {
    resizeTextarea();
  }, [message]);

  useEffect(() => {
    createMessageService?.setMessage({ content: message, isQuestion: isQuestion });
  }, [message, isQuestion]);

  return (
    <form
      className={clsx(
        'chat__form',
        activeTab !== Tab.QUESTIONS && room.settings?.hasQuestions && 'chat__form--question',
        editedMessageService?.editedMessage && 'chat__form--edit',
        isFormDisabled && 'hidden'
      )}
      action="#"
    >
      {quoteService?.quote && (
        <div className="chat__form-header">
          <blockquote className="chat__quote" tabIndex={0}>
            <span className="chat__user">{quoteService?.quote?.author?.fullName}</span>
            <div className="chat__text" dangerouslySetInnerHTML={{ __html: quoteService?.quote?.body }} />
          </blockquote>
          <button className="chat__close" type="button" title="Удалить" onClick={onClearQuoteBtnClick}>
            <CloseIcon />
          </button>
        </div>
      )}

      {editedMessageService?.editedMessage && (
        <div className="chat__form-header">
          <p className="chat__form-state">Редактирование сообщения</p>
          <button className="chat__close" type="button" title="Удалить" onClick={onClearEditedMessageBtnClick}>
            <CloseIcon />
          </button>
        </div>
      )}

      <textarea
        className="field field--text field--full-width"
        name="new-message"
        rows={1}
        id="new-message"
        placeholder="Отправить сообщение"
        onChange={onTextareaChange}
        value={message}
        disabled={isFormDisabled}
      />

      <div className="chat__group-bottom">
        {activeTab === Tab.CHAT && room.settings?.hasQuestions && (
          <label className="chat__checkbox checkbox checkbox--small">
            <input
              className="checkbox__input"
              id="question"
              type="checkbox"
              onChange={onQuestionChange}
              checked={isQuestion}
              disabled={isFormDisabled || !!editedMessageService?.editedMessage}
            />
            <span className="checkbox__text">Задать вопрос</span>
          </label>
        )}
        {showCharactersCounter && (
          <p
            className={`chat__character-counter ${isCharacterLimitExceeded ? 'chat__character-counter--warning' : ''} ${
              Math.abs(CHARACTER_LIMIT - message.length) >= CHARACTERS_OVER_LIMIT_TO_SHOW_WIDER_COUNTER
                ? 'chat__character-counter--wide'
                : ''
            }`}
          >
            <span>{CHARACTER_LIMIT - message.length}</span>
            <span className="sr-only" aria-atomic="true" aria-live="polite">
              {isCharacterLimitExceeded ? 'Вы превысили ограничение длины. Лишних симоволов —' : 'Осталось символов —'}
              {Math.abs(CHARACTER_LIMIT - message.length)}
            </span>
          </p>
        )}
        <button
          className="chat__button-submit"
          type="button"
          onClick={onSubmitBtnClick}
          disabled={isCharacterLimitExceeded}
        >
          Отправить
          <PaperAirplaneIcon />
        </button>
      </div>
    </form>
  );
};
