import { AppDispatch } from '../store';
import { Message } from '../models/Message';
import {
  addMessage,
  deleteMessage,
  selectMessage,
  unselectMessage,
  editMessage,
  questionUpdateMessage,
  approveMessage,
  EditedMessagePayload,
  updateMessageReactions,
} from '../features/messages/messages.slice';
import {
  setPinnedMessage,
  checkPinnedMessageEditing,
  checkPinnedMessageDeleting,
} from '../features/settings/settings.slice';

import { NAMESPACE, ServerMessageEvent } from './MessageEvent';
import { ServerReactionEvent } from './ReactionEvent';
import { ServerEvent, EventSubscriberInterface } from './EventSubscriber';
import { Notify } from '../components/Notify';

export default class MessageEventSubscriber implements EventSubscriberInterface {
  eventPrefix: string = NAMESPACE;

  private dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  getSubscribedEvents(): ServerEvent[] {
    return [
      { name: ServerMessageEvent.QUESTION_ASKED, handler: this.onQuestionAsked } as ServerEvent,
      { name: ServerMessageEvent.QUESTION_ANSWERED, handler: this.onQuestionAnswered } as ServerEvent,
      { name: ServerMessageEvent.QUESTION_SELECTED, handler: this.onQuestionSelected } as ServerEvent,
      { name: ServerMessageEvent.QUESTION_UNSELECTED, handler: this.onQuestionUnselected } as ServerEvent,
      { name: ServerMessageEvent.UPDATED, handler: this.onMessageUpdate } as ServerEvent,
      { name: ServerReactionEvent.REACTION_UPDATED, handler: this.onReactionUpdated } as ServerEvent,
      { name: ServerMessageEvent.SAVED, handler: this.onMessageSaved } as ServerEvent,
      { name: ServerMessageEvent.EDITED, handler: this.onMessageEdited } as ServerEvent,
      { name: ServerMessageEvent.EDIT_REJECTED, handler: this.onMessageEditRejected } as ServerEvent,
      { name: ServerMessageEvent.DELETED, handler: this.onMessageDeleted } as ServerEvent,
      { name: ServerMessageEvent.DELETED_NOTIFIED, handler: this.onMessageDeletedNotified } as ServerEvent,
      { name: ServerMessageEvent.APPROVED, handler: this.onMessageApproved } as ServerEvent,
      { name: ServerMessageEvent.REJECTED, handler: this.onMessageRejected } as ServerEvent,
      { name: ServerMessageEvent.MARKED, handler: this.onMessageMarked } as ServerEvent,
      { name: ServerMessageEvent.MODERATION_REQUESTED, handler: this.onModerationRequested } as ServerEvent,
      { name: ServerMessageEvent.ERROR, handler: this.onError } as ServerEvent,
      { name: ServerMessageEvent.PINNED, handler: this.onPin } as ServerEvent,
      { name: ServerMessageEvent.UNPINNED, handler: this.onUnpin } as ServerEvent,
    ];
  }

  onQuestionAsked(message: Message): void {
    this.dispatch(questionUpdateMessage(message));
  }

  onQuestionAnswered(message: Message): void {
    this.dispatch(questionUpdateMessage(message));
  }

  onQuestionSelected(messageId: string): void {
    this.dispatch(selectMessage(messageId));
  }

  onQuestionUnselected(messageId: string): void {
    this.dispatch(unselectMessage(messageId));
  }

  onMessageUpdate(message: Message): void {
    this.dispatch(questionUpdateMessage(message));
  }
  onReactionUpdated(messageReactions: { messageId: Message['_id']; reactions: Message['reactions'] }): void {
    this.dispatch(updateMessageReactions(messageReactions));
  }
  onMessageSaved(message: Message): void {
    this.dispatch(addMessage(message));
  }

  onMessageEdited(payload: EditedMessagePayload): void {
    this.dispatch(editMessage(payload));
    this.dispatch(checkPinnedMessageEditing(payload));
  }

  onMessageEditRejected(errorMessage: string): void {
    Notify.error(errorMessage);
  }

  onMessageRejected(messageId: string): void {
    this.dispatch(deleteMessage(messageId));
  }

  onMessageApproved(messageId: string): void {
    this.dispatch(approveMessage(messageId));
  }

  onMessageDeleted(messageId: string): void {
    this.dispatch(deleteMessage(messageId));
    this.dispatch(checkPinnedMessageDeleting(messageId));
  }

  onMessageDeletedNotified(message: string): void {
    Notify.info(message);
  }

  onMessageMarked(message: Message): void {
    this.dispatch(addMessage(message));
  }

  onModerationRequested(): void {
    Notify.info('Ваше сообщение было отправлено на модерацию.');
  }

  onError(errorMessage: string): void {
    Notify.error(errorMessage);
  }

  onPin(messageId: string): void {
    this.dispatch(setPinnedMessage(messageId));
  }

  onUnpin(): void {
    this.dispatch(setPinnedMessage(undefined));
  }
}
