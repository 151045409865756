import React, { useContext } from 'react';
import { Message as MessageInterface } from '../models/Message';
import { SocketServiceContext } from '../services/SocketServiceContext';
import { ClientMessageApprovedEvent, ClientMessageRejectedEvent } from '../services/MessageEvent';
import { CheckIcon, TrashIcon } from '@primer/octicons-react';

export const ModerationButtons: React.FC<{ message: MessageInterface }> = (props: { message: MessageInterface }) => {
  const socketService = useContext(SocketServiceContext);
  const { message } = props;

  const onAcceptModerationBtnClick = () => {
    socketService?.emit(new ClientMessageApprovedEvent(message));
  };

  const onDeclineModerationBtnClick = () => {
    socketService?.emit(new ClientMessageRejectedEvent(message));
  };

  return (
    <>
      <button className="chat__inspect-button" type="button" onClick={onAcceptModerationBtnClick}>
        <CheckIcon />
        Одобрить
      </button>
      <button className="chat__inspect-button" type="button" onClick={onDeclineModerationBtnClick}>
        <TrashIcon />
        Отклонить
      </button>
    </>
  );
};
