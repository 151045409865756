import React from 'react';

import { CommentDiscussionIcon, ChecklistIcon, PeopleIcon, HourglassIcon, QuestionIcon } from '@primer/octicons-react';
import { Badge } from './Badge';

interface Props {
  isPublic: boolean;
}

export const Tabs: React.FC<Props> = (props: Props) => {
  const { isPublic } = props;

  return (
    <nav className="tabs tabs--normal tabs--chat">
      <ul className="tabs__list">
        <li className="tabs__item  tabs__item--active">
          <button type="button" title="Чат" className="tabs__item-link" id="chat" tabIndex={-1}>
            <CommentDiscussionIcon verticalAlign="middle" />
            <span> Чат</span>
          </button>
        </li>
        {isPublic && (
          <React.Fragment>
            <li className="tabs__item">
              <button type="button" title="Вопросы" className="tabs__item-link" id="questions">
                <QuestionIcon verticalAlign="middle" />
                <span> Вопросы </span>
                <Badge text="1" size="small" />
              </button>
            </li>
            <li className="tabs__item tabs__item--notify">
              <button
                type="button"
                title="Модерация"
                className="tabs__item-link tabs__item-link--moderation"
                id="moderation"
              >
                <HourglassIcon verticalAlign="middle" />
                <span> Модерация</span>
                <Badge text="88" size="small" color="orange" />
              </button>
            </li>
            <li className="tabs__item">
              <button type="button" title="Правила" className="tabs__item-link" id="rules">
                <ChecklistIcon verticalAlign="middle" />
                <span> Правила</span>
              </button>
            </li>
            <li className="tabs__item">
              <button type="button" title="Пользователи" className="tabs__item-link" id="users">
                <PeopleIcon verticalAlign="middle" />
                <span> Пользователи</span>
                <Badge text="188" size="small" />
              </button>
            </li>
          </React.Fragment>
        )}
      </ul>
    </nav>
  );
};
