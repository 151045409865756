import { io, Socket } from 'socket.io-client';
import { SocketEvents } from '../features/socket/socket-events.enum';
import {
  changeAppLoadingStatus,
  changeAppErrorStatus,
  changeConnectedStatus,
} from '../features/settings/settings.slice';

const socketUrl = process.env.REACT_APP_SOCKET_IO_SERVER;
const socketPort = process.env.REACT_APP_SOCKET_IO_PORT;

import { AppDispatch } from '../store';
import { EventSubscriberInterface, ServerEvent, ClientEventInterface } from './EventSubscriber';
import { Notify } from '../components/Notify';

export class SocketService {
  private dispatch: AppDispatch;
  private socket: Socket;

  constructor(dispatch: AppDispatch, subscribers: EventSubscriberInterface[]) {
    this.socket = this.connect();
    this.dispatch = dispatch;

    this.initialize();

    subscribers.forEach((subscriber: EventSubscriberInterface) => {
      const events = subscriber.getSubscribedEvents();

      events.forEach((event: ServerEvent) => {
        const name = `server.${subscriber.eventPrefix}.${event.name}`;
        this.socket.on(name, event.handler.bind(this));
      });
    });
  }

  emit(event: ClientEventInterface): void {
    this.socket.emit(`client.${event.getName()}`, event.getPayload());
  }

  disconnect(): void {
    this.socket.disconnect();
  }

  private connect(): Socket {
    return io(`${socketUrl}:${socketPort}`, {
      auth: {
        token: this.getToken(),
      },
      transports: ['websocket'],
    });
  }

  private getToken(): string {
    const token = window.location.hash.substring(1);
    if (token) {
      this.storeToken(token);
      return token;
    }
    const storage = this.getStorage();
    if (storage) {
      return storage.getItem('token') || '';
    }
    return '';
  }

  private storeToken(token: string): void {
    const storage = this.getStorage();
    if (storage) {
      storage.setItem('token', token);
      window.location.hash = '';
      history.replaceState(null, '', ' ');
      if (window.location.pathname) {
        window.location.pathname = '';
      }
    }
  }

  private getStorage(): Storage | null | undefined {
    try {
      if (window) {
        if (window.localStorage) {
          return localStorage;
        }
        if (window.sessionStorage) {
          return sessionStorage;
        }
        return null;
      }
      return null;
    } catch (err) {
      console.log(err);
    }
  }

  private initialize() {
    try {
      this.socket.once(SocketEvents.CONNECT, () => {
        // reconnection status
        this.socket.on(SocketEvents.CONNECT, () => {
          this.dispatch(changeConnectedStatus(true));
          this.socket.emit(SocketEvents.GET_MESSAGES);
          // Notify.success('Соединение восстановлено');
        });

        // process error events
        this.socket.on(SocketEvents.ERROR_MESSAGE, (message: string) => Notify.error(message));
        this.socket.on(SocketEvents.ERROR, (message: string) => {
          Notify.error(message);
          this.dispatch(changeAppLoadingStatus(false));
          this.dispatch(changeAppErrorStatus(true));
        });

        this.socket.on(SocketEvents.DISCONNECT, () => {
          // Notify.error('Произошёл разрыв соединения с сервером');
        });
      });
    } catch (err) {
      console.log(err);
    }
  }
}
