import React, { MouseEvent, useContext, useState } from 'react';

import { Modal } from './Modal';
import Tutor from '../../assets/img/components/tutor-face.svg';
import { Notify } from '../Notify';
import { changeCuratorCalledStatus } from '../../features/settings/settings.slice';
import { ClientModeratorCalledEvent } from '../../services/RoomEvent';
import { Room } from '../../models/Room';
import { SocketServiceContext } from '../../services/SocketServiceContext';
import { CurrentUserContext } from '../../services/CurrentUserContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

interface Props {
  closeModal?: () => void;
  isStreamMode?: boolean;
}

export const ModalCurator: React.FC<Props> = (props: Props) => {
  const { closeModal } = props;
  const dispatch = useDispatch();
  const room = useSelector((state: RootState) => state.settings.room);
  const [isModeratorCalled, setModeratorCalledStatus] = useState<boolean>(room?.settings?.moderator?.isCalled || false);
  const socketService = useContext(SocketServiceContext);
  const currentUser = useContext(CurrentUserContext);

  const onCuratorCallBtnClick = (evt: MouseEvent) => {
    if (isModeratorCalled && !currentUser?.canModerateMessages()) {
      const btn = evt.currentTarget as HTMLButtonElement;
      btn.disabled = true;
      Notify.info('Куратор уже был вызван, дождитесь его прихода');
      closeModal && closeModal();
      return;
    }
    setModeratorCalledStatus(!isModeratorCalled);
    dispatch(changeCuratorCalledStatus(isModeratorCalled));
    socketService?.emit(new ClientModeratorCalledEvent(room as Room));
    closeModal && closeModal();
  };

  return (
    <Modal name="curator">
      <div className="modal__wrapper">
        <Tutor />
        <div className="text-container">
          <h2>Куратор готов прийти на&nbsp;помощь, но…</h2>
          <p>
            Обратите внимание, что куратор&nbsp; не&nbsp;занимается проверкой проекта. Если у&nbsp;вас появились вопросы
            к&nbsp;проверяющему наставнику, задайте их&nbsp;здесь в&nbsp;чате. Наставник ответит на&nbsp;них, как только
            у&nbsp;него появится свободное время.
          </p>
          <p>
            Если вы&nbsp;не&nbsp;согласны с&nbsp;оценкой или у&nbsp;вас есть вопросы по&nbsp;организационной стороне
            курса, то&nbsp;тогда зовите куратора, он&nbsp;обязательно вам поможет.
          </p>
          <p>Что делаем дальше?</p>
        </div>
        <div className="modal__buttons">
          <button className="modal__button button" onClick={onCuratorCallBtnClick}>
            Вызываем куратора
          </button>
          <button className="modal__button button button--orange" onClick={() => closeModal && closeModal()}>
            Не&nbsp;вызываем куратора
          </button>
        </div>
      </div>
    </Modal>
  );
};
