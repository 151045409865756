import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { useDispatch, useSelector } from 'react-redux';
import { Message as MessageInterface } from '../models/Message';
import { REACTIONS_LIMIT } from '../constants/constants';
import { Tab } from '../constants/tabs';
import { ReplyButton } from './moderation/ReplyButton';
import { QuestionStatusButton } from './moderation/QuestionStatusButton';
import { PinnedStatusButton } from './moderation/PinnedStatusButton';
import { DeleteMessageButton } from './moderation/DeleteMessageButton';
import { EditMessageButton } from './moderation/EditMessageButton';
import { UserStatusButton } from './moderation/UserStatusButton';
import { AddReactionButton } from './reactions/AddReactionButton';
import { AddReactionBotButton } from './reactions/AddReactionBotButton';
import { ModerationButtons } from './ModerationButtons';
import { UserInfo } from './UserInfo';
import { MessageQuote } from './MessageQuote';
import { RootState } from '../store';
import { CurrentUserContext } from '../services/CurrentUserContext';
import { setFollowBottom } from '../features/settings/settings.slice';
import { Separator } from './Separator';
import { PinIcon } from '@primer/octicons-react';
import clsx from 'clsx';
import { SelectQuestionButton } from './moderation/SelectQuestionButton';
import { ClientQuestionSelectEvent, ClientQuestionUnselectEvent } from '../services/MessageEvent';
import { SocketServiceContext } from '../services/SocketServiceContext';
import { MessageReactions } from './reactions/MessageReactions';

const MAX_MINUTES_TO_EDIT_MESSAGE = 5;

export interface MessageElement {
  message: MessageInterface;
  isSameUser?: boolean;
  dateGroup: string;
}

let messageDateGroup = '';

export const Message: React.FC<MessageElement> = (props: MessageElement) => {
  const { message, isSameUser, dateGroup } = props;
  const dispatch = useDispatch();

  const room = useSelector((state: RootState) => state.settings.room);
  const isArrowShow = useSelector((state: RootState) => state.settings.isArrowShow);
  const currentUser = useContext(CurrentUserContext);
  const socketService = useContext(SocketServiceContext);
  const canModerate = currentUser?.canModerateMessages();
  const isCurrentUserMessage = currentUser?.getUserId() === message.author._id;
  const isListener = currentUser?.isListener();
  const isBlocked = currentUser?.isBlocked();
  const activeTab = useSelector((state: RootState) => state.settings.activeTab);
  const pinnedMessageId = useSelector((state: RootState) => state.settings.room.pinnedMessage);
  const isPinned = message?._id === pinnedMessageId;
  const isTimeToEditExpired = moment().diff(message.createdAt, 'minutes') > MAX_MINUTES_TO_EDIT_MESSAGE;
  const isSelected = useSelector((state: RootState) => state.messages.list).filter((msg) => msg._id === message._id)[0]
    .isSelected;
  const showAddReactionButton = !message.reactions?.length || message.reactions?.length < REACTIONS_LIMIT;

  const isSameDate = messageDateGroup === dateGroup;
  messageDateGroup = dateGroup;
  const isFirstUnread = false;

  useEffect(() => {
    if (!isArrowShow) {
      dispatch(setFollowBottom(true));
    }
  }, [activeTab]);

  useEffect(() => {
    const handler = (event: MouseEvent): void => {
      if (currentUser?.canModerateMessages()) {
        const elem = event.composedPath().find((p) => {
          if (p instanceof Element) {
            return p.id === message._id;
          }
        });
        if (elem && message.isQuestion) {
          !isSelected
            ? socketService?.emit(new ClientQuestionSelectEvent(message))
            : socketService?.emit(new ClientQuestionUnselectEvent(message));
        }
      }
    };
    document.addEventListener('dblclick', handler, false);

    return () => document.removeEventListener('dblclick', handler);
  });

  const htmlMessage = `${
    activeTab === Tab.MODERATION && message.editedBody ? message.editedBody.trim() : message.body.trim()
  }`;

  return (
    <>
      {!isSameDate || isFirstUnread ? <Separator date={!isSameDate ? dateGroup : ''} unread={isFirstUnread} /> : ''}
      <li
        className={clsx(
          'chat__item',
          room.settings?.hasImages && 'chat__item--avatar',
          message.isQuestion && 'chat__item--question',
          isSameUser && isSameDate && 'chat__item--by-same-user',
          !isSameDate && 'chat__item--new-date',
          isSelected && 'chat__item--selected'
        )}
        data-date={dateGroup}
        tabIndex={0}
        id={message._id}
      >
        <article className="chat__message">
          <header className="chat__message-header">
            {!isSameUser || !isSameDate ? <UserInfo user={message.author} /> : ''}
            {isPinned ? (
              <span className="chat__pin" title="Сообщение закреплено">
                <PinIcon verticalAlign="unset" />
              </span>
            ) : (
              ''
            )}
            <time className="chat__time" dateTime={moment(message.createdAt).format('YYYY-MM-DD HH:mm')}>
              {moment(message.createdAt).format('HH:mm')}
            </time>
          </header>
          <div
            className={clsx('chat__text', message?.isEdited && 'chat__text--edited')}
            dangerouslySetInnerHTML={{
              __html: htmlMessage,
            }}
          />
        </article>
        {message.quote ? <MessageQuote quote={message.quote} /> : ''}
        {activeTab !== Tab.QUESTIONS && message.reactions?.length ? <MessageReactions message={message} /> : ''}
        {!isListener && (
          <div className="chat__inspect">
            {activeTab !== Tab.MODERATION ? (
              <>
                {activeTab !== Tab.QUESTIONS && showAddReactionButton && !isBlocked && (
                  <>
                    <AddReactionButton message={message} />
                    {canModerate && <AddReactionBotButton message={message} />}
                  </>
                )}
                {isCurrentUserMessage && !isTimeToEditExpired && <EditMessageButton message={message} />}
                <ReplyButton message={message} />
                {room.settings?.hasQuestions && canModerate && <QuestionStatusButton message={message} />}
                {canModerate && (
                  <>
                    <PinnedStatusButton message={message} isPinned={isPinned} />
                    <DeleteMessageButton message={message} />
                    <UserStatusButton userId={message.author._id} />
                    {message.isQuestion && activeTab === Tab.QUESTIONS && <SelectQuestionButton message={message} />}
                  </>
                )}
              </>
            ) : (
              <ModerationButtons message={message} />
            )}
          </div>
        )}
      </li>
    </>
  );
};
