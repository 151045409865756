import React, { useState } from 'react';

export type ReactionServiceInterface = {
  messageId: string;
  botMessageId: string;
  setMessageId: (messageId: string) => void;
  setBotMessageId: (messageId: string) => void;
  clearMessageId: () => void;
  clearBotMessageId: () => void;
  targetPosition: DOMRect | positionType;
  setTargetPosition: (targetPosition: DOMRect) => void;
  clearTargetPosition: () => void;
};

type positionType = {
  height: number;
  width: number;
  x: number;
  y: number;
};

const defaultPosition = {
  height: 0,
  width: 0,
  x: 0,
  y: 0,
};

export const ReactionServiceContext = React.createContext<ReactionServiceInterface | null>(null);

const ReactionService: React.FC<React.ReactNode> = ({ children }) => {
  const [messageId, setMessageId] = useState<string>('');
  const [botMessageId, setBotMessageId] = useState<string>('');
  const [targetPosition, setTargetPosition] = useState<DOMRect | positionType>(defaultPosition);

  const clearMessageId = (): void => {
    setMessageId('');
  };

  const clearBotMessageId = (): void => {
    setBotMessageId('');
  };

  const clearTargetPosition = (): void => {
    setTargetPosition(defaultPosition);
  };

  return (
    <ReactionServiceContext.Provider
      value={{
        messageId,
        setMessageId,
        clearMessageId,
        botMessageId,
        setBotMessageId,
        clearBotMessageId,
        targetPosition,
        setTargetPosition,
        clearTargetPosition,
      }}
    >
      {children}
    </ReactionServiceContext.Provider>
  );
};

export default ReactionService;
