import { Message, ClientMessage, EditedMessage } from '../models/Message';
import { ClientEventInterface } from './EventSubscriber';

export const NAMESPACE = 'message';

export enum ServerMessageEvent {
  SAVED = 'sent',
  DELETED = 'deleted',
  DELETED_NOTIFIED = 'deleted.notified',
  MARKED = 'marked',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  QUESTION_ASKED = 'question.asked',
  QUESTION_ANSWERED = 'question.answered',
  QUESTION_SELECTED = 'question.selected',
  QUESTION_UNSELECTED = 'question.unselected',
  UPDATED = 'updated',
  EDITED = 'edited',
  EDIT_REJECTED = 'edit-rejected',
  MODERATION_REQUESTED = 'blocked',
  ERROR = 'error',
  PINNED = 'pinned',
  UNPINNED = 'unpinned',
}

abstract class AbstractClientMessageEvent implements ClientEventInterface {
  name = '';

  message: Message;

  constructor(message: Message) {
    this.message = message;
  }

  getName(): string {
    return `${NAMESPACE}.${this.name}`;
  }

  abstract getPayload(): unknown;
}

export class ClientMessageSentEvent implements ClientEventInterface {
  message: ClientMessage;

  constructor(message: ClientMessage) {
    this.message = message;
  }

  getName(): string {
    return `${NAMESPACE}.sent`;
  }

  getPayload(): unknown {
    return this.message;
  }
}

export class ClientMessageEditedEvent implements ClientEventInterface {
  message: EditedMessage;

  constructor(message: EditedMessage) {
    this.message = message;
  }

  getName(): string {
    return `${NAMESPACE}.edited`;
  }

  getPayload(): unknown {
    return { id: this.message.id, body: this.message.body };
  }
}

export class ClientQuestionAskedEvent extends AbstractClientMessageEvent {
  name = 'question.asked';

  getPayload(): unknown {
    return this.message._id;
  }
}

export class ClientQuestionAnsweredEvent extends AbstractClientMessageEvent {
  name = 'question.answered';

  getPayload(): unknown {
    return this.message._id;
  }
}

export class ClientQuestionSelectEvent extends AbstractClientMessageEvent {
  name = 'question.selected';

  getPayload(): unknown {
    return this.message._id;
  }
}

export class ClientQuestionUnselectEvent extends AbstractClientMessageEvent {
  name = 'question.unselected';

  getPayload(): unknown {
    return this.message._id;
  }
}

export class ClientMessageApprovedEvent extends AbstractClientMessageEvent {
  name = 'approved';

  getPayload(): unknown {
    return this.message._id;
  }
}

export class ClientMessageRejectedEvent extends AbstractClientMessageEvent {
  name = 'rejected';

  getPayload(): unknown {
    return this.message._id;
  }
}

export class ClientMessageDeletedEvent extends AbstractClientMessageEvent {
  name = 'deleted';

  getPayload(): unknown {
    return this.message._id;
  }
}

export class ClientMessagePinEvent extends AbstractClientMessageEvent {
  name = 'pinned';

  getPayload(): unknown {
    return this.message._id;
  }
}

export class ClientMessageUnpinEvent extends AbstractClientMessageEvent {
  name = 'unpinned';

  getPayload(): unknown {
    return this.message._id;
  }
}
