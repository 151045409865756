import React from 'react';

export interface BadgeElement {
  color?: 'green' | 'blue' | 'yellow' | 'purple' | 'red' | 'orange';
  size?: 'small' | 'large';
  text: string;
}

export const Badge: React.FC<BadgeElement> = (props: BadgeElement) => {
  const { color, text, size } = props;
  return (
    <span className={`badge  ${color ? 'badge--' + color : ''} ${size ? 'badge--' + size : ''}`}>
      <span className="badge__text">{text}</span>
    </span>
  );
};
