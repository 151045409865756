import { AppDispatch } from '../store';

import { NAMESPACE, ServerCurrentUserEvent } from './CurrentUserEvent';
import { ServerEvent, EventSubscriberInterface } from './EventSubscriber';
import { changeBlockStatus } from '../features/settings/settings.slice';
import { Notify } from '../components/Notify';

export default class CurrentUserEventSubscriber implements EventSubscriberInterface {
  eventPrefix: string = NAMESPACE;

  private dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  getSubscribedEvents(): ServerEvent[] {
    return [
      { name: ServerCurrentUserEvent.BLOCKED, handler: this.onMeBlocked } as ServerEvent,
      { name: ServerCurrentUserEvent.UNBLOCKED, handler: this.onMeUnblocked } as ServerEvent,
    ];
  }

  onMeBlocked(msg: string): void {
    this.dispatch(changeBlockStatus(true));
    Notify.error(msg);
  }

  onMeUnblocked(msg: string): void {
    this.dispatch(changeBlockStatus(false));
    Notify.success(msg);
  }
}
