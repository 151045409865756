import React, { FormEvent, ReactElement, useContext } from 'react';

import { CommentDiscussionIcon, ChecklistIcon, PeopleIcon, HourglassIcon, QuestionIcon } from '@primer/octicons-react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from '../constants/tabs';
import { changeActiveTab } from '../features/settings/settings.slice';
import { Message as MessageInterface } from '../models/Message';
import { User as UserInterface } from '../models/User';
import { Badge, Color, Size } from './Badge';
import { RootState } from '../store';
import { CurrentUserContext } from '../services/CurrentUserContext';
import clsx from 'clsx';

interface TabProps {
  id: string;
  title: string;
  icon?: ReactElement;
  counter?: number;
  counterActive?: number;
  color?: Color;
  showNotificator?: boolean;
}

const TabItem = (props: TabProps): ReactElement => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state: RootState) => state.settings.activeTab);

  const onTabChange = (evt: FormEvent<HTMLElement>) => {
    dispatch(changeActiveTab(evt.currentTarget.id || Tab.CHAT));
  };

  return (
    <li
      className={clsx(
        'tabs__item',
        activeTab === props.id && 'tabs__item--active',
        props.showNotificator && props.counter !== 0 && 'tabs__item--notify'
      )}
    >
      <button type="button" title={props.title} className="tabs__item-link" id={props.id} onClick={onTabChange}>
        {props.icon}
        <span> {props.title}</span>
        {props.counter ? (
          <Badge
            text={props.counterActive ? `${props.counterActive} / ${props.counter}` : props.counter}
            size={Size.SMALL}
            color={props.color}
          />
        ) : (
          ''
        )}
      </button>
    </li>
  );
};

export const Tabs: React.FC = () => {
  const isPublic = true;

  const currentUser = useContext(CurrentUserContext);
  const { room, messagesList, usersList } = useSelector((state: RootState) => {
    return {
      room: state.settings.room,
      messagesList: state.messages.list,
      usersList: state.users,
    };
  });

  const canModerate = currentUser?.canModerateMessages();

  const moderationsCount = messagesList.filter((msg: MessageInterface) => msg.needModeration).length;

  const questionsCount = messagesList.filter((msg: MessageInterface) => !msg.needModeration && msg.isQuestion).length;

  const usersOnline = usersList.filter((usr: UserInterface) => usr.isOnline).length;

  return (
    <nav className="tabs tabs--normal tabs--chat">
      <ul className="tabs__list">
        <TabItem id={Tab.CHAT} title="Чат" icon={<CommentDiscussionIcon verticalAlign="middle" />} />
        {isPublic && (
          <>
            {room.settings?.hasModeration && (
              <TabItem id={Tab.RULES} title="Правила" icon={<ChecklistIcon verticalAlign="middle" />} />
            )}
            {canModerate ? (
              <>
                {room.settings?.hasQuestions && (
                  <TabItem
                    id={Tab.QUESTIONS}
                    title="Вопросы"
                    icon={<QuestionIcon verticalAlign="middle" />}
                    counter={questionsCount}
                    color={Color.YELLOW}
                    showNotificator={true}
                  />
                )}
                {room.settings?.hasModeration && (
                  <TabItem
                    id={Tab.MODERATION}
                    title="Модерация"
                    icon={<HourglassIcon verticalAlign="middle" />}
                    counter={moderationsCount}
                    color={Color.ORANGE}
                    showNotificator={true}
                  />
                )}
                <TabItem
                  id={Tab.USERS}
                  title="Пользователи"
                  icon={<PeopleIcon verticalAlign="middle" />}
                  counter={usersList.length}
                  counterActive={usersOnline}
                />
              </>
            ) : (
              ''
            )}
          </>
        )}
      </ul>
    </nav>
  );
};
