import React from 'react';
import { useStore } from 'react-redux';
import { ClientEventInterface } from './EventSubscriber';
import { SocketService } from './SocketService';
import MessageEventSubscriber from './MessageEventSubscriber';
import MessagesListEventSubscriber from './MessagesListEventSubscriber';
import UserEventSubscriber from './UserEventSubscriber';
import CurrentUserEventSubscriber from './CurrentUserEventSubscriber';
import RoomEventSubscriber from './RoomEventSubscriber';
import UsersListEventSubscriber from './UsersListEventSubscriber';

export type SocketServiceInterface = {
  emit(event: ClientEventInterface): void;
  disconnect(): void;
};

export const SocketServiceContext = React.createContext<SocketServiceInterface | null>(null);

const SocketServiceProvider: React.FC<React.ReactNode> = ({ children }) => {
  const dispatch = useStore().dispatch;

  const subscribers = [
    new RoomEventSubscriber(dispatch),
    new MessageEventSubscriber(dispatch),
    new MessagesListEventSubscriber(dispatch),
    new UserEventSubscriber(dispatch),
    new CurrentUserEventSubscriber(dispatch),
    new UsersListEventSubscriber(dispatch),
  ];

  return (
    <SocketServiceContext.Provider value={new SocketService(dispatch, subscribers)}>
      {children}
    </SocketServiceContext.Provider>
  );
};

export default SocketServiceProvider;
