/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import avatar1x from '../../assets/img/avatar@1x.jpg';
import avatar2x from '../../assets/img/avatar@2x.jpg';
import { ReplyIcon, TrashIcon, QuestionIcon, MuteIcon, PencilIcon, PinIcon } from '@primer/octicons-react';
export interface MessageElement {
  isEdited?: boolean;
  isSelected?: boolean;
  isQuestion?: boolean;
  isPinned?: boolean;
  isAvatar?: boolean;
  user: string;
  isBlocked?: boolean;
  isMe?: boolean;
  userRole: string;
  time: string;
  message: any;
  quotedMessage: any;
  isModerationTab?: boolean;
}

export const Message: React.FC<MessageElement> = (props: MessageElement) => {
  const {
    user,
    isEdited,
    isBlocked,
    isMe,
    userRole,
    time,
    message,
    isSelected,
    isAvatar,
    isQuestion,
    isPinned,
    quotedMessage,
    isModerationTab,
  } = props;
  return (
    <li
      className={`chat__item ${isSelected ? 'chat__item--selected' : ''} ${isAvatar ? 'chat__item--avatar' : ''} ${
        isQuestion ? 'chat__item--question' : ''
      }`}
      tabIndex={0}
    >
      <article className="chat__message">
        <header className="chat__message-header">
          {isAvatar && (
            <img className="chat__avatar" src={avatar1x} srcSet={` ${avatar2x} 2x`} width="30" height="30" alt="" />
          )}
          <span
            className={`chat__user ${isMe && 'chat__user--me'} ${userRole === 'curator' && 'chat__user--curator'} ${
              userRole === 'tutor' && 'chat__user--tutor'
            }`}
          >
            {user}
          </span>
          {isBlocked ? (
            <span className="chat__user-status">
              <MuteIcon verticalAlign="middle" />
              Забанен
            </span>
          ) : (
            ''
          )}
          <time className="chat__time" dateTime="YYYY-MM-DD HH:mm">
            {time}
          </time>
        </header>
        <div className="chat__text">{message}</div>
      </article>
      {/* eslint-disable-next-line no-constant-condition */}
      {quotedMessage ? (
        <blockquote className="chat__quote">
          {isAvatar && (
            <img className="chat__avatar" src={avatar1x} srcSet={` ${avatar2x} 2x`} width="30" height="30" alt="" />
          )}
          <span className="chat__user">Автор Цитаты</span>
          <div className="chat__text">{quotedMessage}</div>
        </blockquote>
      ) : (
        ''
      )}
      {isModerationTab ? (
        <p className="chat__inspect">
          <button className="chat__inspect-button" type="button">
            Одобрить
          </button>
          <button className="chat__inspect-button" type="button">
            Отклонить
          </button>
        </p>
      ) : (
        <p className="chat__inspect">
          <button className="chat__inspect-button chat__inspect-button--question" type="button" title="Отредактировать">
            <PencilIcon />
            Отредактировать
          </button>
          <button className="chat__inspect-button" type="button" title="Ответить">
            <ReplyIcon />
            Ответить
          </button>
          <button
            className={`chat__inspect-button chat__inspect-button--question ${
              isQuestion ? 'chat__inspect-button--active' : ''
            }`}
            type="button"
            title={isQuestion ? 'Снять вопрос' : 'Сделать вопросом'}
          >
            <QuestionIcon />
            {isQuestion ? 'Снять вопрос' : 'Сделать вопросом'}
          </button>
          <button className="chat__inspect-button" type="button" title="Удалить">
            <TrashIcon />
            Удалить
          </button>
          <button
            className={`chat__inspect-button chat__inspect-button--pinned ${
              isQuestion ? 'chat__inspect-button--active' : ''
            }`}
            type="button"
            title={isPinned ? 'Открепить сообщение' : 'Закрепить сообщение'}
          >
            <PinIcon />
            {isPinned ? 'Открепить сообщение' : 'Закрепить сообщение'}
          </button>
        </p>
      )}
    </li>
  );
};
