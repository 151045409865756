import React, { useState } from 'react';

import { Message } from '../models/Message';

export type QuoteServiceInterface = {
  quote: Message | null;
  setQuote: (quote: Message) => void;
  clearQuote: () => void;
};

export const QuoteServiceContext = React.createContext<QuoteServiceInterface | null>(null);

const QuoteService: React.FC<React.ReactNode> = ({ children }) => {
  const [quote, setQuote] = useState<Message | null>(null);

  const clearQuote = (): void => {
    setQuote(null);
  };

  return (
    <QuoteServiceContext.Provider value={{ quote, setQuote, clearQuote }}>{children}</QuoteServiceContext.Provider>
  );
};

export default QuoteService;
