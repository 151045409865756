import React from 'react';

import { Scrollable } from '../components/Scrollable';

export const Rules: React.FC = () => {
  return (
    <Scrollable>
      <div className="chat__rules text-container">
        <h2>Правила чата</h2>
        <ul>
          <li>Общение в чате должно быть доброжелательным и уважительным.</li>
          <li>В чате не должно быть оскорблений, флуда, спама и холиваров.</li>
          <li>
            Чтобы задать вопрос преподавателю, поставьте галочку <strong>Задать вопрос</strong>.
          </li>
          <li>Все ссылки проходят модерацию и попадают в чат только после одобрения куратора.</li>
          <li>
            Если вы нарушите правила, куратор заберёт у вас возможность отправлять сообщения, но вы всё ещё сможете
            читать чат и смотреть лекцию.
          </li>
          <li>Имена кураторов и авторов выделены красным цветом, наставников — синим, ваше имя — зеленым.</li>
        </ul>
      </div>
    </Scrollable>
  );
};
