import React, { FormEvent, useEffect, useState } from 'react';

// компоненты
import { User } from '../models/User';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Scrollable } from '../components/Scrollable';
import { UserItem } from '../components/UserItem';

export const Users: React.FC = () => {
  const users = useSelector((state: RootState) => state.users);

  const [searchString, setSearchString] = useState('');
  const [foundUsers, setFoundUsers] = useState<User[]>([]);
  const [onlineMode, setOnlineMode] = useState<boolean>(false);
  const [currentUsers, setCurrentUsers] = useState<User[]>([]);

  const onSearch = (evt: FormEvent) => {
    const input = evt.target as HTMLInputElement;
    setSearchString(input.value);
    const regEx = new RegExp(input.value, 'gi');
    const foundedUsers = users.filter((user: User) => user.fullName.match(regEx) || user._id.toString().match(regEx));
    setFoundUsers(foundedUsers);
  };

  const onOnlyOnlineBtnClick = (evt: FormEvent) => {
    const toggle = evt.currentTarget as HTMLInputElement;
    setOnlineMode(toggle.checked);
  };

  useEffect(() => {
    setCurrentUsers(searchString ? foundUsers : users);
  }, [searchString, users]);

  return (
    <>
      <form className="chat__search" action="#">
        <input
          className="field field--text field--full-width"
          type="text"
          placeholder="Поиск пользователя…"
          onChange={onSearch}
        />
        <label className="chat__search-toggle toggle">
          <input className="toggle__input" type="checkbox" onClick={onOnlyOnlineBtnClick} />
          <span className="toggle__text">Только онлайн</span>
        </label>
      </form>
      <div className="chat__container">
        <Scrollable>
          <ul className="chat__user-list">
            {currentUsers.map((user, i) => {
              if (onlineMode && !user.isOnline) {
                return;
              }
              return <UserItem user={user} key={i} />;
            })}
          </ul>
        </Scrollable>
      </div>
    </>
  );
};
