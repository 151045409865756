import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';

import { KebabHorizontalIcon, CommentIcon, DeviceCameraVideoIcon } from '@primer/octicons-react';
import { ModalFullscreen } from './modals/ModalFullscreen';

export const ESC_KEY = 'Escape';

export const Settings = (): ReactElement => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [mode, setMode] = useState<string>();
  const ref = React.useRef(null);

  useOnClickOutside(ref, () => setMenuOpened(false));
  const onMenuToggle = () => setMenuOpened(!menuOpened);

  const onModeBtnClick = (evt: MouseEvent) => {
    const target = evt.currentTarget as HTMLButtonElement;
    setMode(target?.title);
    setModalOpened(true);
    setMenuOpened(false);
    return;
  };

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      setModalOpened(false);
      if (event.key === ESC_KEY && menuOpened) {
        setMenuOpened(false);
      }
    };
    document.addEventListener('keydown', handler);

    return () => document.removeEventListener('keydown', handler);
  });

  return (
    <div className="chat__more-buttons-wrap" ref={ref}>
      <button
        type="button"
        aria-haspopup="menu"
        aria-expanded={menuOpened}
        title="Дополнительные настройки"
        className="chat__more-button chat__more-button--toggle"
        onClick={onMenuToggle}
      >
        <KebabHorizontalIcon />
      </button>
      <ul className="chat__more-buttons-list" role="menu">
        <li className="chat__more-buttons-item" role="menuitem">
          <button
            type="button"
            title="Режим для стрима чата"
            className="chat__more-button chat__more-button--stream"
            onClick={onModeBtnClick}
          >
            <DeviceCameraVideoIcon />
            Режим для стрима
          </button>
        </li>
        <li className="chat__more-buttons-item" role="menuitem">
          <button
            type="button"
            title="Показать только вопросы"
            className="chat__more-button chat__more-button--questions"
            onClick={onModeBtnClick}
          >
            <CommentIcon />
            Только вопросы
          </button>
        </li>
      </ul>
      {modalOpened && <ModalFullscreen isChatStreamMode={mode === 'Режим для стрима чата'} />}
    </div>
  );
};
