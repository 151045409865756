import React from 'react';
import { AlertIcon, InfoIcon, IssueClosedIcon, StopIcon } from '@primer/octicons-react';
import { toast } from 'react-toastify';

export const Notify = {
  info: (message: string): void => {
    toast.info(message, { icon: <InfoIcon size="medium" /> });
  },
  warning: (message: string): void => {
    toast.warning(message, { icon: <AlertIcon size="medium" /> });
  },
  error: (message: string): void => {
    toast.error(message, { icon: <StopIcon size="medium" /> });
  },
  success: (message: string): void => {
    toast.success(message, { icon: <IssueClosedIcon size="medium" /> });
  },
};
