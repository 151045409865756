import React, { ReactElement } from 'react';

import UndefinedErrorSVG from '../assets/img/components/undefined-error.svg';

export const ChatError: React.FC = (): ReactElement => {
  return (
    <div className="chat-overlay chat-overlay--error">
      <UndefinedErrorSVG />
      <p>
        При подключении к чату произошла какая-то ошибка. Пожалуйста, сохраняйте спокойствие и попробуйте перезагрузить
        страницу.
      </p>

      <button className="button" type="button" onClick={() => location.reload()}>
        Перезагрузить страницу
      </button>
    </div>
  );
};
