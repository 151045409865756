import '@htmlacademy/kit/dist/css/fonts.css';
import './assets/css/bundles/chat.pcss';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { store } from './store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Root as RootScreen } from './screens/Root';

if (process.env.SENTRY_DSN && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
  });
}

const routerBaseUrl = '/';

ReactDOM.render(
  <Provider store={store}>
    <Router basename={routerBaseUrl}>
      <RootScreen />
    </Router>
  </Provider>,
  document.querySelector('#root')
);
