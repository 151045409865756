import { AppDispatch } from '../store';
import { Message } from '../models/Message';
import { storeAllMessages } from '../features/messages/messages.slice';
import { changeRequestedMessagesStatus } from '../features/settings/settings.slice';

import { ServerEvent, EventSubscriberInterface } from './EventSubscriber';
import { NAMESPACE, ServerMessagesListEvent } from './MessagesListEvent';
import { Notify } from '../components/Notify';

export default class MessagesListEventSubscriber implements EventSubscriberInterface {
  eventPrefix: string = NAMESPACE;

  private dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  getSubscribedEvents(): ServerEvent[] {
    return [
      { name: ServerMessagesListEvent.LOADED, handler: this.onMessagesLoaded } as ServerEvent,
      { name: ServerMessagesListEvent.ERROR, handler: this.onMessagesLoaded } as ServerEvent,
    ];
  }

  onMessagesLoaded(messages: Message[]): void {
    this.dispatch(storeAllMessages(messages));
    this.dispatch(changeRequestedMessagesStatus(false));
  }

  onMessagesError(errorMsg: string): void {
    Notify.error(errorMsg);
  }
}
