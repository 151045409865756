import React from 'react';
import { Quote as QuoteInterface } from '../models/Message';
import { UserInfo } from './UserInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { User } from '../models/User';

interface Props {
  quote: QuoteInterface;
}

export const MessageQuote: React.FC<Props> = (props: Props) => {
  const { quote } = props;

  const user = useSelector((state: RootState) => state.users).find((user: User) => user._id === quote.author._id);

  return (
    <blockquote className="chat__quote">
      <UserInfo user={user} isInQuote={true} />
      <div className="chat__text" dangerouslySetInnerHTML={{ __html: quote.body.trim() }} />
    </blockquote>
  );
};
