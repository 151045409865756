import React, { useContext } from 'react';
import { Message as MessageInterface } from '../../models/Message';
import { ReactionServiceContext } from '../../services/ReactionContext';

import SmileyIcon from '../../assets/img/components/icon-add-reaction.svg';

type Props = {
  message: MessageInterface;
};

export const AddReactionButton: React.FC<Props> = (props: Props) => {
  const { message } = props;

  const reactionService = useContext(ReactionServiceContext);

  const isAriaExpanded = Boolean(reactionService?.messageId);

  const onButtonClick = (evt: React.MouseEvent) => {
    const target = evt.currentTarget as HTMLButtonElement;
    reactionService?.setMessageId(message._id);
    reactionService?.setTargetPosition(target.getBoundingClientRect());
  };

  return (
    <div className="chat__inspect-button-wrapper">
      <button
        className="chat__inspect-button chat__inspect-button--reaction"
        type="button"
        title="Добавить реакцию"
        onClick={onButtonClick}
        aria-controls="modal-reactions"
        aria-expanded={isAriaExpanded}
        aria-label="Добавить реакцию"
      >
        <SmileyIcon />
      </button>
    </div>
  );
};
