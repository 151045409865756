import { combineReducers, configureStore } from '@reduxjs/toolkit';
import messagesSlice from './features/messages/messages.slice';
import usersSlice from './features/users/users.slice';
import settingsSlice from './features/settings/settings.slice';

const rootReducer = combineReducers({
  messages: messagesSlice,
  users: usersSlice,
  settings: settingsSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
