import React, { useContext, useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';

import clsx from 'clsx';
import { ESC_KEY } from '../../constants/constants';

import { Emoji, emojis } from './emojis';
import { Message as MessageInterface } from '../../models/Message';
import { ReactionData } from '../../models/ReactionData';
import { ModalReactionItem } from './ModalReactionItem';
import { ReactionServiceContext } from '../../services/ReactionContext';
import { SocketServiceContext } from '../../services/SocketServiceContext';
import { ClientReactionAddedEvent } from '../../services/ReactionEvent';

export enum Modal {
  WIDTH = 350,
  HEIGHT = 342,
  OFFSET = 20,
  MOBILE_WIDTH = 180,
  MOBILE_HEIGHT = 324,
  MOBILE_OFFSET = 5,
}

type Style = {
  top: number;
  left: number;
};
type Props = {
  messages: MessageInterface[];
};

export const ModalReactions: React.FC<Props> = (props: Props) => {
  const [style, setStyle] = useState<Style>({ top: 0, left: 0 });
  const socketService = useContext(SocketServiceContext);
  const reactionService = useContext(ReactionServiceContext);

  const isModalCalled = Boolean(reactionService?.messageId);
  const isModalOpen = isModalCalled && style.top !== 0 && style.left !== 0;
  const targetMessageid = reactionService?.messageId;
  const targetMessageReactions = props.messages.find((message) => message._id === targetMessageid)?.reactions || [];

  const ref = useRef<HTMLDivElement>(null);

  // Позиционируем пикер
  const getPosition = () => {
    const container = document.querySelector('.chat');

    const currentStyle = {
      top: 0,
      left: 0,
    };

    const currentModal = {
      width: Modal.WIDTH,
      height: Modal.HEIGHT,
      offset: Modal.OFFSET,
    };

    if (container && reactionService && ref.current) {
      if (container?.clientWidth < Modal.WIDTH + Modal.OFFSET * 2) {
        currentModal.offset = Modal.MOBILE_OFFSET;
        if (container?.clientWidth < Modal.WIDTH + Modal.MOBILE_OFFSET * 2) {
          currentModal.width = Modal.MOBILE_WIDTH;
          currentModal.height = Modal.MOBILE_HEIGHT;
        }
      }

      const defaultLeftPosition = reactionService?.targetPosition.x;
      const offsetLeft = container?.clientWidth - defaultLeftPosition - currentModal.width;
      currentStyle.left =
        offsetLeft > currentModal.offset
          ? defaultLeftPosition
          : defaultLeftPosition - (currentModal.offset - offsetLeft) > currentModal.offset
          ? defaultLeftPosition - (currentModal.offset - offsetLeft)
          : currentModal.offset;

      const defaultTopPosition = reactionService?.targetPosition.y;
      const offsetTop = container?.clientHeight - defaultTopPosition - currentModal.height;
      currentStyle.top =
        offsetTop > currentModal.offset
          ? defaultTopPosition
          : defaultTopPosition - (currentModal.offset - offsetTop) > currentModal.offset
          ? defaultTopPosition - (currentModal.offset - offsetTop)
          : currentModal.offset;
    }
    setStyle(currentStyle);
  };

  const closeModal = () => {
    setStyle({ top: 0, left: 0 });
    reactionService?.clearMessageId();
  };

  // Изначально устанавливаем положение положение пикера
  useEffect(() => {
    getPosition();
  }, [reactionService]);

  // Закрываем при ресайзе окна
  useEffect(() => {
    window.addEventListener('resize', closeModal);
    return () => {
      window.removeEventListener('resize', closeModal);
    };
  }, [isModalCalled]);

  // Закрываем по клику по оверлею
  useOnClickOutside(ref, () => closeModal());

  // Закрываем по нажатию esc
  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      if (event.key === ESC_KEY) {
        closeModal();
      }
    };
    document.addEventListener('keydown', handler);

    return () => document.removeEventListener('keydown', handler);
  });

  // Поддерживаем эффект ховера на сообщении при открытии пикера
  useEffect(() => {
    if (!targetMessageid) {
      return;
    }
    const targetMessageElement = document.querySelector(`[id=${CSS.escape(targetMessageid)}]`);
    const activeMessageClass = 'chat__item--hover';
    if (targetMessageElement) {
      targetMessageElement.classList.add(activeMessageClass);
    }
    return () => {
      if (targetMessageElement) {
        targetMessageElement.classList.remove(activeMessageClass);
      }
    };
  }, [isModalCalled]);

  const onClickCallback = (emojiId: ReactionData['emojiId']) => {
    if (!targetMessageReactions.find((reaction) => reaction.emojiId === emojiId)?.hasUserReacted) {
      const reaction: ReactionData = {
        emojiId,
        messageId: targetMessageid || '',
      };
      socketService?.emit(new ClientReactionAddedEvent(reaction));
    }
    closeModal();
  };

  return (
    <div className={clsx('chat__reaction-picker-overlay', !isModalOpen && 'hidden')} id="modal-reactions">
      <div className="chat__reaction-picker" ref={ref} style={{ top: style.top, left: style.left }}>
        <div className="chat__reaction-picker-wrapper">
          <ul className="chat__reaction-picker-list">
            {emojis.map((emoji: Emoji, i: number) => (
              <ModalReactionItem key={i} emojiId={emoji.id} onClickCallback={onClickCallback} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
