import { AppDispatch } from '../store';
import { ServerEvent, EventSubscriberInterface } from './EventSubscriber';
import { NAMESPACE, ServerUsersListEvent } from './UsersListEvent';
import { updateUsersOnlineStatus } from '../features/users/users.slice';

export default class UsersListEventSubscriber implements EventSubscriberInterface {
  eventPrefix: string = NAMESPACE;

  private dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  getSubscribedEvents(): ServerEvent[] {
    return [{ name: ServerUsersListEvent.ONLINE_SENT, handler: this.onOnlineUsersReceived } as ServerEvent];
  }

  onOnlineUsersReceived(users: number[]): void {
    this.dispatch(updateUsersOnlineStatus(users));
  }
}
