import React, { useContext } from 'react';
import { QuestionIcon } from '@primer/octicons-react';
import { Message as MessageInterface } from '../../models/Message';
import { SocketServiceContext } from '../../services/SocketServiceContext';
import { ClientQuestionAskedEvent, ClientQuestionAnsweredEvent } from '../../services/MessageEvent';
import clsx from 'clsx';

export interface Props {
  message: MessageInterface;
}

export const QuestionStatusButton: React.FC<Props> = (props: Props) => {
  const { message } = props;

  const socketService = useContext(SocketServiceContext);

  const onBtnClick = () => {
    socketService?.emit(
      message.isQuestion ? new ClientQuestionAnsweredEvent(message) : new ClientQuestionAskedEvent(message)
    );
  };

  const title = message.isQuestion ? 'Снять вопрос' : 'Сделать вопросом';

  return (
    <button
      className={clsx(
        'chat__inspect-button',
        'chat__inspect-button--question',
        message.isQuestion && 'chat__inspect-button--active'
      )}
      type="button"
      onClick={onBtnClick}
      title={title}
    >
      <QuestionIcon />
      {title}
    </button>
  );
};
