import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { ModalCurator } from './modals/ModalCurator';
import { CurrentUserContext } from '../services/CurrentUserContext';
import { changeCuratorCalledStatus } from '../features/settings/settings.slice';
import { ClientModeratorLogoutEvent } from '../services/RoomEvent';
import { Room } from '../models/Room';
import { useHistory } from 'react-router-dom';
import { SocketServiceContext } from '../services/SocketServiceContext';
import { PersonAddIcon, SignOutIcon } from '@primer/octicons-react';

export const CallCurator = (): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const room = useSelector((state: RootState) => state.settings.room);
  const currentUser = useContext(CurrentUserContext);
  const socketService = useContext(SocketServiceContext);
  const canLeaveFromChat = currentUser?.canModerateMessages() && room.settings?.moderator?.isCalled;

  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const onModalOpen = () => setModalOpened(!modalOpened);

  const onLeaveChat = () => {
    dispatch(changeCuratorCalledStatus(false));
    socketService?.emit(new ClientModeratorLogoutEvent(room as Room));
    socketService?.disconnect();
    history.push('/exit');
  };

  if (!room.settings?.moderator?.isAvailable) {
    return <></>;
  }

  return (
    <>
      {room.settings?.moderator?.isCalled ? (
        <>
          {canLeaveFromChat && (
            <button
              type="button"
              onClick={onLeaveChat}
              title="Выйти из чата"
              className="chat__nav-button chat__curator-button"
            >
              <SignOutIcon />
              Выйти из чата
            </button>
          )}
        </>
      ) : (
        <>
          <button
            type="button"
            onClick={onModalOpen}
            aria-haspopup="dialog"
            aria-expanded={modalOpened}
            title="Вызов куратора"
            className="chat__nav-button chat__curator-button"
          >
            <PersonAddIcon />
            Вызов куратора
          </button>
          {modalOpened && <ModalCurator closeModal={onModalOpen} />}
        </>
      )}
    </>
  );
};
