import { ClientEventInterface } from './EventSubscriber';
import { Room } from '../models/Room';
export const NAMESPACE = 'room';

export enum ServerRoomEvent {
  USER_ADDED = 'user.added',
  LOADED = 'loaded',
  ERROR = 'error',
  MODERATOR_CALLED = 'moderator.called',
  MODERATOR_JOINED = 'moderator.joined',
  MODERATOR_LOGOUT = 'moderator.logout',
}

abstract class AbstractClientRoomEvent implements ClientEventInterface {
  name = '';

  room: Room;

  constructor(room: Room) {
    this.room = room;
  }

  getName(): string {
    return `${NAMESPACE}.${this.name}`;
  }

  abstract getPayload(): unknown;
}

export class ClientModeratorCalledEvent extends AbstractClientRoomEvent {
  name = 'moderator.called';

  getPayload(): unknown {
    return;
  }
}

export class ClientModeratorLogoutEvent extends AbstractClientRoomEvent {
  name = 'moderator.logout';

  getPayload(): unknown {
    return;
  }
}
