import React, { ReactElement } from 'react';

// img
import avatar1x from '../../assets/img/avatar@1x.jpg';
import avatar2x from '../../assets/img/avatar@2x.jpg';
import unknown_raccoon from '../../assets/img/unknown-raccoon.svg';

import { ReplyIcon, TrashIcon, QuestionIcon, PinIcon } from '@primer/octicons-react';
import SmileyIcon from '../../assets/img/components/icon-add-reaction.svg';

// компоненты
import { Tabs } from '../components-markup/Tabs';
import { Message } from '../components-markup/Message';
import { Separator } from '../components-markup/Separator';
import { PinnedMessage } from '../components-markup/PinnedMessage';
import { Loader } from '../components-markup/Loader';
import { MoreButtons } from '../components-markup/MoreButtons';
import { MessageFormQuestion } from '../components-markup/MessageFormQuestion';
import { ArrowDown } from '../components-markup/ArrowDown';
import { Emoji } from '../components-markup/Emoji';

export const PublicChatMarkup = (): ReactElement => {
  const isPublic = true;
  const isMessageQuoted = true;

  return (
    <div className="chat" aria-label="Чат">
      <header className="chat__header">
        <Tabs {...{ isPublic }} />

        <MoreButtons />
      </header>

      <main className="chat__tab" aria-labelledby="chat">
        <PinnedMessage
          message={
            'A long time ago in a galaxy far, far away... It is a period of civil war. Rebel spaceships, striking from a hidden base, have won their first victory against the evil Galactic Empire. During the battle, Rebelspie s managed to steal secret plans to the Empire’s ultimate weapon, the DEATH STAR, and space station with enough power to destroy an entire planet.'
          }
        />
        <div className="chat__container">
          <div className="scrollable">
            <div className="scrollable__wrapper" id="chat-window">
              <div className="scrollable__content chat__list-wrapper">
                <Loader />
                <ul className="chat__list">
                  <Separator date="22 августа 2018" unread={true} />
                  <li className="chat__item chat__item--avatar" tabIndex={0}>
                    <article className="chat__message">
                      <header className="chat__message-header">
                        <img
                          className="chat__avatar"
                          src={avatar1x}
                          srcSet={` ${avatar2x} 2x`}
                          width="30"
                          height="30"
                          alt=""
                        />
                        <span className="chat__user chat__user--me">Дельфина Дельфинская [Вы]</span>
                        <span className="chat__pin" title="Сообщение закреплено">
                          <PinIcon verticalAlign="unset" />
                        </span>
                        <time className="chat__time" dateTime="YYYY-MM-DD HH:mm">
                          11:11
                        </time>
                      </header>
                      <div className="chat__text">Ваше сообщение</div>
                    </article>
                    <footer className="chat__item-footer">
                      <ul className="chat__reactions">
                        <li className="chat__reactions-item">
                          <button type="button" className="chat__reactions-button chat__reactions-button--active">
                            <Emoji id="1F4AA" />
                            <span className="sr-only"></span>
                            <span className="chat__reactions-counter">1</span>
                          </button>
                        </li>
                        <li className="chat__reactions-item">
                          <button type="button" className="chat__reactions-button">
                            <Emoji id="1F44D" />
                            <span className="sr-only"></span>
                            <span className="chat__reactions-counter">10</span>
                          </button>
                        </li>
                        <li className="chat__reactions-item">
                          <div className="chat__inspect-button-wrapper">
                            <button
                              className="chat__inspect-button chat__inspect-button--reaction"
                              type="button"
                              title="Поставить реакцию"
                            >
                              <SmileyIcon />
                            </button>
                            {/* <ul className="chat__reaction-picker">
                              <li className="chat__reaction-picker-item">
                                <button type="button" className="chat__reaction-picker-button">
                                  <Emoji id="1f600" />
                                  <span className="sr-only"></span>
                                </button>
                              </li>
                              <li className="chat__reaction-picker-item">
                                <button type="button" className="chat__reaction-picker-button">
                                  <Emoji id="1f44D" />
                                  <span className="sr-only"></span>
                                </button>
                              </li>
                              <li className="chat__reaction-picker-item">
                                <button type="button" className="chat__reaction-picker-button">
                                  <Emoji id="1f44D" />
                                  <span className="sr-only"></span>
                                </button>
                              </li>
                              <li className="chat__reaction-picker-item">
                                <button type="button" className="chat__reaction-picker-button">
                                  <Emoji id="1f44D" />
                                  <span className="sr-only"></span>
                                </button>
                              </li>
                              <li className="chat__reaction-picker-item">
                                <button type="button" className="chat__reaction-picker-button">
                                  <Emoji id="1f44D" />
                                  <span className="sr-only"></span>
                                </button>
                              </li>
                              <li className="chat__reaction-picker-item">
                                <button type="button" className="chat__reaction-picker-button">
                                  <Emoji id="1F44D" />
                                  <span className="sr-only"></span>
                                </button>
                              </li>
                            </ul> */}
                          </div>
                        </li>
                      </ul>
                    </footer>
                    <div className="chat__inspect">
                      <div className="chat__inspect-button-wrapper">
                        <button
                          className="chat__inspect-button chat__inspect-button--reaction"
                          type="button"
                          title="Поставить реакцию"
                        >
                          <SmileyIcon />
                          Поставить реакцию
                        </button>
                        {/* <ul className="chat__reaction-picker">
                          <li className="chat__reaction-picker-item">
                            <button type="button" className="chat__reaction-picker-button">
                              <Emoji id="1f600" />
                              <span className="sr-only"></span>
                            </button>
                          </li>
                          <li className="chat__reaction-picker-item">
                            <button type="button" className="chat__reaction-picker-button">
                              <Emoji id="1f44D" />
                              <span className="sr-only"></span>
                            </button>
                          </li>
                          <li className="chat__reaction-picker-item">
                            <button type="button" className="chat__reaction-picker-button">
                              <Emoji id="1f44D" />
                              <span className="sr-only"></span>
                            </button>
                          </li>
                          <li className="chat__reaction-picker-item">
                            <button type="button" className="chat__reaction-picker-button">
                              <Emoji id="1f44D" />
                              <span className="sr-only"></span>
                            </button>
                          </li>
                          <li className="chat__reaction-picker-item">
                            <button type="button" className="chat__reaction-picker-button">
                              <Emoji id="1f44D" />
                              <span className="sr-only"></span>
                            </button>
                          </li>
                          <li className="chat__reaction-picker-item">
                            <button type="button" className="chat__reaction-picker-button">
                              <Emoji id="1F44D" />
                              <span className="sr-only"></span>
                            </button>
                          </li>
                        </ul> */}
                      </div>
                      <button className="chat__inspect-button" type="button" title="Ответить">
                        <ReplyIcon />
                        Ответить
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--question"
                        type="button"
                        title="Снять вопрос"
                      >
                        <QuestionIcon />
                        Cнять вопрос
                      </button>
                      <button className="chat__inspect-button" type="button" title="Удалить">
                        <TrashIcon />
                        Удалить
                      </button>
                      <button className="chat__inspect-button" type="button" title="Закрепить">
                        <PinIcon />
                        Закрепить
                      </button>
                    </div>
                  </li>
                  <li className="chat__item chat__item--avatar" tabIndex={0}>
                    <article className="chat__message">
                      <header className="chat__message-header">
                        <img
                          className="chat__avatar"
                          src={avatar1x}
                          srcSet={` ${avatar2x} 2x`}
                          width="30"
                          height="30"
                          alt=""
                        />
                        <span className="chat__user chat__user--me">
                          Дельфина Дельфиновна эль Дельфина фон Дальфина Дельфинская [Вы]
                        </span>
                        <span className="chat__pin" title="Сообщение закреплено">
                          <PinIcon verticalAlign="unset" />
                        </span>
                        <time className="chat__time" dateTime="YYYY-MM-DD HH:mm">
                          00:00
                        </time>
                      </header>
                      <div className="chat__text">
                        Ваше сообщение Ваше сообщение Ваше сообщение Ваше сообщение Ваше сообщение Ваше сообщение Ваше
                        сообщение Ваше сообщение
                      </div>
                    </article>
                    <blockquote className="chat__quote">
                      <img
                        className="chat__avatar"
                        src={avatar1x}
                        srcSet={` ${avatar2x} 2x`}
                        width="30"
                        height="30"
                        alt=""
                      />

                      <span className="chat__user">Элеонора Элеоноровна</span>
                      <div className="chat__text">
                        <p>Вопрос пользователя?</p>
                        <p>
                          <i>курсив</i> <b>жирный</b> <em>выделение</em> <strong>усиление</strong> <span>спан </span>{' '}
                          <i>курсив</i> <b>жирный</b> <em>выделение</em> <strong>усиление</strong> <span>спан </span>{' '}
                          <i>курсив</i> <b>жирный</b> <em>выделение</em> <strong>усиление</strong> <span>спан </span>
                          <s>зачеркивание</s> <a href="#">ссылка</a>
                        </p>
                        <ul>
                          <li>Список</li>
                          <li>Список</li>
                        </ul>
                      </div>
                    </blockquote>
                    <p className="chat__inspect">
                      <button className="chat__inspect-button" type="button" title="Ответить">
                        <ReplyIcon />
                        Ответить
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--question"
                        type="button"
                        title="Снять вопрос"
                      >
                        <QuestionIcon />
                        Cнять вопрос
                      </button>
                      <button className="chat__inspect-button" type="button" title="Удалить">
                        <TrashIcon />
                        Удалить
                      </button>
                      <button className="chat__inspect-button" type="button" title="Закрепить">
                        <PinIcon />
                        Закрепить
                      </button>
                    </p>
                  </li>
                  <li className="chat__item chat__item--avatar chat__item--by-same-user" tabIndex={0}>
                    <article className="chat__message">
                      <header className="chat__message-header">
                        <span className="chat__pin" title="Сообщение закреплено">
                          <PinIcon verticalAlign="unset" />
                        </span>
                        <time className="chat__time" dateTime="YYYY-MM-DD HH:mm">
                          13:40
                        </time>
                      </header>
                      <div className="chat__text">Ваше сообщение</div>
                    </article>
                    <p className="chat__inspect">
                      <button className="chat__inspect-button" type="button" title="Ответить">
                        <ReplyIcon />
                        Ответить
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--question"
                        type="button"
                        title="Снять вопрос"
                      >
                        <QuestionIcon />
                        Cнять вопрос
                      </button>
                      <button className="chat__inspect-button" type="button" title="Удалить">
                        <TrashIcon />
                        Удалить
                      </button>
                      <button className="chat__inspect-button" type="button" title="Закрепить">
                        <PinIcon />
                        Закрепить
                      </button>
                    </p>
                  </li>
                  <Separator unread={true} />
                  <li className="chat__item chat__item--by-same-user" tabIndex={0}>
                    <article className="chat__message">
                      <header className="chat__message-header">
                        <span className="chat__pin" title="Сообщение закреплено">
                          <PinIcon verticalAlign="unset" />
                        </span>
                        <time className="chat__time" dateTime="YYYY-MM-DD HH:mm">
                          15:00
                        </time>
                      </header>
                      <div className="chat__text">
                        Ваше сообщение Ваше сообщение Ваше сообщение Ваше сообщение Ваше сообщение Ваше сообщение Ваше
                        сообщение Ваше сообщение Ваше сообщение Ваше сообщение Ваше сообщение
                      </div>
                    </article>
                    <p className="chat__inspect">
                      <button className="chat__inspect-button" type="button" title="Ответить">
                        <ReplyIcon />
                        Ответить
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--question"
                        type="button"
                        title="Снять вопрос"
                      >
                        <QuestionIcon />
                        Cнять вопрос
                      </button>
                      <button className="chat__inspect-button" type="button" title="Удалить">
                        <TrashIcon />
                        Удалить
                      </button>
                      <button className="chat__inspect-button" type="button" title="Закрепить">
                        <PinIcon />
                        Закрепить
                      </button>
                    </p>
                  </li>
                  <Message
                    isSelected={false}
                    isQuestion={false}
                    isAvatar={true}
                    user="Дельфина Дельфинская [Вы]"
                    userRole="user"
                    isMe={true}
                    isBlocked={true}
                    time="13 дней назад"
                    message={<p>Ваше сообщение</p>}
                    quotedMessage=""
                    isModerationTab={false}
                  />
                  <li className="chat__item chat__item--avatar" tabIndex={0}>
                    <article className="chat__message">
                      <header className="chat__message-header">
                        <img className="chat__avatar" src={unknown_raccoon} width="30" height="30" alt="" />
                        <span className="chat__user chat__user--tutor">tutor</span>
                        <time className="chat__time" dateTime="2011-11-18 14:54:39.929Z">
                          13 дней назад
                        </time>
                      </header>
                      <div className="chat__text">
                        <p>Ответ на вопрос пользователя</p>
                      </div>
                    </article>

                    <blockquote className="chat__quote">
                      <img
                        className="chat__avatar"
                        src={avatar1x}
                        srcSet={` ${avatar2x} 2x`}
                        width="30"
                        height="30"
                        alt=""
                      />
                      <span className="chat__user">Элеонора Элеоноровна</span>
                      <div className="chat__text">
                        <p>Вопрос пользователя?</p>
                        <p>
                          <i>курсив</i> <b>жирный</b> <em>выделение</em> <strong>усиление</strong> <span>спан </span>
                          <s>зачеркивание</s> <a href="#">ссылка</a>
                        </p>
                        <ul>
                          <li>Список</li>
                          <li>Список</li>
                        </ul>
                      </div>
                    </blockquote>

                    <p className="chat__inspect">
                      <button className="chat__inspect-button" type="button" title="Ответить">
                        <ReplyIcon />
                        Ответить
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--question"
                        type="button"
                        title="Снять вопрос"
                      >
                        <QuestionIcon />
                        Cнять вопрос
                      </button>
                      <button className="chat__inspect-button" type="button" title="Удалить">
                        <TrashIcon />
                        Удалить
                      </button>
                      <button className="chat__inspect-button" type="button" title="Закрепить">
                        <PinIcon />
                        Закрепить
                      </button>
                    </p>
                  </li>
                  {/* Ответ на вопрос */}
                  <Message
                    isSelected={false}
                    isQuestion={false}
                    isAvatar={true}
                    user="admin"
                    userRole="curator"
                    isMe={false}
                    time="13 дней назад"
                    message={<p>Ответ на вопрос пользователя</p>}
                    quotedMessage={
                      <>
                        <span className="chat__user">Элеонора Элеоноровна</span>
                        <div className="chat__text">
                          <p>Вопрос пользователя?</p>
                          <p>
                            <i>курсив</i> <b>жирный</b> <em>выделение</em> <strong>усиление</strong> <span>спан </span>
                            <s>зачеркивание</s> <a href="#">ссылка</a>
                          </p>
                          <ul>
                            <li>Список</li>
                            <li>Список</li>
                          </ul>
                        </div>
                      </>
                    }
                    isModerationTab={false}
                  />
                  <Message
                    isSelected={true}
                    isQuestion={false}
                    isAvatar={false}
                    user="Вениамин Вениаминович"
                    userRole="user"
                    isMe={false}
                    time="13 дней назад"
                    message={<p>Ответ лектора</p>}
                    quotedMessage=""
                    isModerationTab={false}
                  />
                  <Message
                    isSelected={false}
                    isQuestion={false}
                    isAvatar={false}
                    user="Вениамин Вениаминович"
                    userRole="user"
                    isMe={false}
                    time="13 дней назад"
                    message={<p>Просто сообщение участника чата</p>}
                    quotedMessage=""
                    isModerationTab={false}
                  />
                  <Message
                    isSelected={false}
                    isQuestion={false}
                    isAvatar={false}
                    user="Вениамин Вениаминович"
                    userRole="user"
                    isMe={true}
                    time="13 дней назад"
                    message={<p>Просто сообщение участника чата</p>}
                    quotedMessage={
                      <>
                        <p>Просто сообщение участника чата</p>
                        <p>
                          <i>курсив</i> <b>жирный</b> <em>выделение</em> <strong>усиление</strong> <span>спан </span>
                          <s>зачеркивание</s> <a href="#">ссылка</a>
                        </p>
                        <p>
                          Просто довольно длинное сообщение участника чата длинное сообщениеучастника чатаю Просто
                          довольно длинное сообщение участника чата длинное сообщение участника чатаю Просто довольно
                          длинное сообщение участника чата длинное сообщение участника чата
                        </p>
                        <p>
                          Просто довольно длинное сообщение участника чата длинное сообщение участника чатаю Просто
                          довольно длинное сообщение участника чата длинное сообщение участника чатаю Просто довольно
                          длинное сообщение участника чата длинное сообщение участника чата
                        </p>
                        <ul>
                          <li>Список</li>
                          <li>Список</li>
                        </ul>
                        <ol>
                          <li>Список</li>
                          <li>Список</li>
                        </ol>
                      </>
                    }
                    isModerationTab={false}
                  />
                  <Message
                    isSelected={false}
                    isQuestion={true}
                    isPinned={true}
                    isAvatar={false}
                    user="Элеонора Элеоноровна"
                    userRole="user"
                    isMe={true}
                    time="13 дней назад"
                    message={<p>Вопрос пользователя</p>}
                    quotedMessage=""
                    isModerationTab={false}
                  />
                  <Message
                    isSelected={false}
                    isQuestion={false}
                    isAvatar={false}
                    user="admin"
                    userRole="curator"
                    isMe={false}
                    time="13 дней назад"
                    message={<p>Ответ на вопрос пользователя</p>}
                    quotedMessage={
                      <>
                        <span className="chat__user">Элеонора Элеоноровна</span>
                        <div className="chat__text">
                          <p>Вопрос пользователя?</p>
                        </div>
                      </>
                    }
                    isModerationTab={false}
                  />
                  <Message
                    isSelected={false}
                    isQuestion={false}
                    isAvatar={false}
                    user="Дельфина Дельфинская"
                    userRole="tutor"
                    isMe={false}
                    time="13 дней назад"
                    message={
                      <p>
                        Ỏ͖͈̞̩͎̻̫̫̜͉̠̫͕̭̭̫̫̹̗̹͈̼̠̖͍͚̥͈̮̼͕̠̤̯̻̥̬̗̼̳̤̳̬̪̹͚̞̼̠͕̼̠̦͚̫͔̯̹͉͉̘͎͕̼̣̝͙̱̟̹̩̟̳̦̭͉̮̖̭̣̣̞̙̗̜̺̭̻̥͚͙̝̦̲̱͉͖͉̰̦͎̫̣̼͎͍̠̮͓̹̹͉̤̰̗̙͕͇͔̱ ͬͬͤͬͦỎ͖͈̞̩͎̻̫̫̜͉̠̫͕̭̭̫̫̹̗̹͈̼̠̖͍͚̥͈̮̼͕̠̤̯̻̥̬̗̼̳̤̳̬̪̹͚̞̼̠͕̼̠̦͚̫͔̯̹͉ вопрос у меня какой то длинный длинный вопрос у меня какой то длинный длинный вопрос у меня
                        какой то длинный длинный вопрос у меня какой то длинный длинный вопрос?
                      </p>
                    }
                    quotedMessage=""
                    isModerationTab={false}
                  />
                </ul>
              </div>
            </div>

            <ArrowDown />
          </div>
        </div>

        <MessageFormQuestion {...{ isMessageQuoted }} />
      </main>
    </div>
  );
};
