import { User } from '../models/User';
import { UserRole } from '../constants/roles';

export class CurrentUser {
  private user: User;

  constructor(user: User) {
    this.user = user;
  }

  canModerateMessages(): boolean {
    return this.user.role === UserRole.ADMIN || this.user.role === UserRole.AUTHOR;
  }

  getUserId(): number {
    return this.user._id;
  }

  isBlocked(): boolean {
    return this.user.isBlocked;
  }

  getMentionedUsers(): string[] | undefined {
    return this.user.mentionedUsers;
  }

  isListener(): boolean {
    return this.user.role === UserRole.LISTENER || this.user.isListener === true;
  }
}
