import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../models/User';

export const initialState: User[] = [];

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUsersData(state, { payload }: { payload: User }): void {
      if (!state.find((usr) => usr._id === payload._id)) {
        state.push({
          role: payload.role,
          image: payload.image || '',
          fullName: payload.fullName,
          _id: payload._id,
          userId: payload.userId,
          isBlocked: payload.isBlocked,
          isDeactivated: payload.isDeactivated,
          lastVisit: new Date(),
          mentionedUsers: [],
          isOnline: true,
        });
      }
    },
    updateUser(state, { payload }: { payload: { _id: number; isBlocked: boolean } }): void {
      const index = state.findIndex((usr) => usr._id === payload._id);
      const newArray = [...state];
      newArray[index].isBlocked = payload.isBlocked;
    },
    updateUsersOnlineStatus(state, { payload }: { payload: number[] }): void {
      state.forEach((user) => {
        const isInOnlineList = payload.find((userId) => userId === user._id);
        user.isOnline = !!isInOnlineList;
      });
    },
  },
});

export const { addUsersData, updateUser, updateUsersOnlineStatus } = users.actions;
export default users.reducer;
