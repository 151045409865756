import { ReactionData, BotReactionsData } from '../models/ReactionData';
import { ClientEventInterface } from './EventSubscriber';

export const NAMESPACE = 'message';

export enum ServerReactionEvent {
  REACTION_UPDATED = 'reaction.updated',
}

abstract class AbstractClientReactionEvent implements ClientEventInterface {
  name = '';

  reaction: ReactionData;

  constructor(reaction: ReactionData) {
    this.reaction = reaction;
  }

  getName(): string {
    return `${NAMESPACE}.${this.name}`;
  }

  getPayload(): ReactionData {
    return this.reaction;
  }
}

export class ClientReactionAddedEvent extends AbstractClientReactionEvent {
  name = 'reaction.added';
}

export class ClientReactionRemovedEvent extends AbstractClientReactionEvent {
  name = 'reaction.removed';
}

export class ClientReactionBotEvent {
  name = 'reaction.bot';

  reactions: BotReactionsData;

  constructor(reactions: BotReactionsData) {
    this.reactions = reactions;
  }

  getName(): string {
    return `${NAMESPACE}.${this.name}`;
  }

  getPayload(): BotReactionsData {
    return this.reactions;
  }
}
