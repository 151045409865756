import React, { useContext } from 'react';
import { SocketServiceContext } from '../../services/SocketServiceContext';
import { CurrentUserContext } from '../../services/CurrentUserContext';
import { ClientReactionAddedEvent, ClientReactionRemovedEvent } from '../../services/ReactionEvent';
import { Message as MessageInterface, Reaction as MessageReaction } from '../../models/Message';
import { ReactionData } from '../../models/ReactionData';
import { REACTIONS_LIMIT } from '../../constants/constants';
import { MessageReactionItem } from './MessageReactionItem';
import { AddReactionButton } from './AddReactionButton';

type Props = {
  message: MessageInterface;
};

export const MessageReactions: React.FC<Props> = (props: Props) => {
  const { message } = props;
  const socketService = useContext(SocketServiceContext);
  const currentUser = useContext(CurrentUserContext);
  const isUserBlocked = currentUser?.isBlocked() || currentUser?.isListener();

  const onClickCallback = (emojiId: ReactionData['emojiId'], byCurrentUser?: boolean) => {
    const reaction: ReactionData = {
      emojiId,
      messageId: message._id || '',
    };
    if (byCurrentUser) {
      socketService?.emit(new ClientReactionRemovedEvent(reaction));
    } else {
      socketService?.emit(new ClientReactionAddedEvent(reaction));
    }
  };

  return (
    <footer className="chat__item-footer">
      {message.reactions && (
        <ul className="chat__reactions">
          {message.reactions.map((reaction: MessageReaction, i: number) => {
            return (
              <MessageReactionItem
                key={i}
                emojiId={reaction.emojiId}
                reactionCounter={reaction.count}
                isReactionByCurrentUser={reaction.hasUserReacted}
                onClickCallback={onClickCallback}
                isDisabled={isUserBlocked}
              />
            );
          })}
          {!isUserBlocked && message.reactions?.length < REACTIONS_LIMIT && (
            <li className="chat__reactions-item chat__reactions-item--add">
              <AddReactionButton message={message} />
            </li>
          )}
        </ul>
      )}
    </footer>
  );
};
