import React, { useContext } from 'react';
import { Message as MessageInterface } from '../../models/Message';
import { ReactionServiceContext } from '../../services/ReactionContext';
import { HubotIcon } from '@primer/octicons-react';

type Props = {
  message: MessageInterface;
};

export const AddReactionBotButton: React.FC<Props> = (props: Props) => {
  const { message } = props;

  const reactionService = useContext(ReactionServiceContext);

  const isAriaExpanded = Boolean(reactionService?.botMessageId);

  const onButtonClick = () => {
    reactionService?.setBotMessageId(message._id);
  };

  return (
    <div className="chat__inspect-button-wrapper">
      <button
        className="chat__inspect-button chat__inspect-button--reaction"
        type="button"
        title="Добавить реакцию"
        onClick={onButtonClick}
        aria-controls="modal-reactions"
        aria-expanded={isAriaExpanded}
        aria-label="Добавить реакцию"
      >
        <HubotIcon />
      </button>
    </div>
  );
};
