import { AppDispatch } from '../store';
import { addUsersData } from '../features/users/users.slice';
import { User } from '../models/User';

import { NAMESPACE, ServerRoomEvent } from './RoomEvent';
import { ServerEvent, EventSubscriberInterface } from './EventSubscriber';
import { Notify } from '../components/Notify';
import { changeAppLoadingStatus, changeCuratorCalledStatus, storeTokenData } from '../features/settings/settings.slice';
import { TokenData } from '../models/TokenData';
import * as Sentry from '@sentry/browser';
import { CURATOR_EXIT_ROUTE } from '../screens/Root';

export default class RoomEventSubscriber implements EventSubscriberInterface {
  eventPrefix: string = NAMESPACE;

  private dispatch: AppDispatch;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  getSubscribedEvents(): ServerEvent[] {
    return [
      { name: ServerRoomEvent.USER_ADDED, handler: this.onUserJoined } as ServerEvent,
      { name: ServerRoomEvent.LOADED, handler: this.onRoomLoaded } as ServerEvent,
      { name: ServerRoomEvent.ERROR, handler: this.onError } as ServerEvent,
      { name: ServerRoomEvent.MODERATOR_CALLED, handler: this.onModeratorCalled } as ServerEvent,
      { name: ServerRoomEvent.MODERATOR_JOINED, handler: this.onModeratorJoined } as ServerEvent,
      { name: ServerRoomEvent.MODERATOR_LOGOUT, handler: this.onModeratorLogout } as ServerEvent,
    ];
  }

  onUserJoined(user: User): void {
    this.dispatch(addUsersData(user));
  }

  onRoomLoaded(data: TokenData): void {
    // Передаём дополнительную инфу по пользователю, чтобы было проще отлавливать ошибки в сентри
    Sentry.setContext('User info', {
      roomId: data?.room?.roomId,
      userId: data?.user?._id,
    });
    this.dispatch(storeTokenData(data));
    data.room.users?.forEach((user: User) => this.dispatch(addUsersData(user)));
    this.dispatch(changeAppLoadingStatus(false));
  }

  onError(blockedMessage: string): void {
    Notify.error(blockedMessage);
    this.dispatch(changeCuratorCalledStatus(false));
  }

  onModeratorCalled(message: string): void {
    Notify.info(message);
    this.dispatch(changeCuratorCalledStatus(true));
  }

  onModeratorJoined(): void {
    if (window.location.pathname !== CURATOR_EXIT_ROUTE) {
      this.dispatch(changeCuratorCalledStatus(true));
    }
  }

  onModeratorLogout(): void {
    this.dispatch(changeCuratorCalledStatus(false));
  }
}
