import React, { ReactElement } from 'react';

import PreloaderSVG from '../assets/img/components/preloader.svg';

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

export const Preloader: React.FC<Props> = (props: Props): ReactElement => {
  const { isLoading, children } = props;

  return (
    <>
      {isLoading ? (
        <div className="chat-overlay chat-overlay--loading">
          <PreloaderSVG />
          <p>Загружаем…</p>
        </div>
      ) : (
        children
      )}
    </>
  );
};
