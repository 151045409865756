import { User } from '../models/User';
import { ClientEventInterface } from './EventSubscriber';

export const NAMESPACE = 'user';

export enum ServerUserEvent {
  BLOCKED = 'blocked',
  UNBLOCKED = 'unblocked',
  ERROR = 'error',
}

abstract class AbstractClientUserEvent implements ClientEventInterface {
  name = '';

  user: User | undefined;

  constructor(user?: User | undefined) {
    this.user = user;
  }

  getName(): string {
    return `${NAMESPACE}.${this.name}`;
  }

  abstract getPayload(): unknown;
}

export class ClientUserBlockedEvent extends AbstractClientUserEvent {
  user: User;
  name = 'blocked';

  constructor(user: User) {
    super();
    this.user = user;
  }

  getPayload(): unknown {
    return this.user._id;
  }
}

export class ClientUserUnblockedEvent extends AbstractClientUserEvent {
  user: User;
  name = 'unblocked';

  constructor(user: User) {
    super();
    this.user = user;
  }

  getPayload(): unknown {
    return this.user._id;
  }
}

export class ClientUserOnlineEvent extends AbstractClientUserEvent {
  name = 'online';

  getPayload(): unknown {
    return;
  }
}
