/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import CloseIcon from '../../assets/img/components/icon-close--octicon.svg';
import { PinIcon } from '@primer/octicons-react';
export interface Props {
  message: any;
}

export const PinnedMessage: React.FC<Props> = (props: Props) => {
  const { message } = props;
  return (
    <article className="chat__pinned">
      <h2 className="chat__pinned-title">
        <PinIcon verticalAlign="unset" />
        Закрепленное сообщение
      </h2>
      <button className="chat__close" type="button" title="Закрыть">
        <CloseIcon />
      </button>
      <blockquote className="chat__pinned-message chat__quote chat__text">
        <p>{message}</p>
        <p>
          <a href="#">Cсылка</a>
        </p>
      </blockquote>
    </article>
  );
};
