import React, { useContext } from 'react';
import { SocketServiceContext } from '../../services/SocketServiceContext';
import { TrashIcon } from '@primer/octicons-react';
import { Message } from '../../models/Message';
import { ClientMessageDeletedEvent } from '../../services/MessageEvent';

export interface Props {
  message: Message;
}

export const DeleteMessageButton: React.FC<Props> = (props: Props) => {
  const { message } = props;
  const socketService = useContext(SocketServiceContext);

  const onBtnClick = () => {
    socketService?.emit(new ClientMessageDeletedEvent(message));
  };

  return (
    <button className="chat__inspect-button" type="button" onClick={onBtnClick} title="Удалить">
      <TrashIcon />
      Удалить
    </button>
  );
};
