import React, { ReactElement, useState, useEffect, MouseEvent } from 'react';
import { ESC_KEY } from '../../components/Settings';

// компоненты
import { Modal } from '../components-markup/Modal';

export const ModalMarkup = (): ReactElement => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [modalName, setModalName] = useState<string>('');
  const onModalOpen = function (evt: MouseEvent) {
    setModalOpened(!modalOpened);
    const btn = evt.currentTarget as HTMLButtonElement;
    if (btn.dataset.modalName) {
      setModalName(btn.dataset.modalName);
    }
  };

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      if (event.key === ESC_KEY && modalOpened) {
        setModalOpened(false);
      }
    };
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  });

  return (
    <div className="chat" aria-label="Чат">
      <button
        onClick={onModalOpen}
        aria-haspopup="dialog"
        aria-expanded={modalOpened}
        title="Вызов куратора"
        data-modal-name="curator"
      >
        Вызов куратора
      </button>
      <br />
      <button
        onClick={onModalOpen}
        aria-haspopup="dialog"
        aria-expanded={modalOpened}
        title="Переход в полноэкранный режим"
        data-modal-name="fullscreen"
      >
        Переход в полноэкранный режим
      </button>
      {modalOpened && <Modal name={modalName} />}
    </div>
  );
};
