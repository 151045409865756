import React, { useState } from 'react';

export interface MessageDraft {
  content: string;
  isQuestion: boolean;
}

export type CreateMessageInterface = {
  message: MessageDraft;
  setMessage: (message: MessageDraft) => void;
  clearMessage: () => void;
};

export const CreateMessageContext = React.createContext<CreateMessageInterface | null>(null);

const CreateMessage: React.FC<React.ReactNode> = ({ children }) => {
  const [message, setMessage] = useState<MessageDraft>({ content: '', isQuestion: false });

  const clearMessage = (): void => {
    setMessage({ content: '', isQuestion: false });
  };

  return (
    <CreateMessageContext.Provider value={{ message, setMessage, clearMessage }}>
      {children}
    </CreateMessageContext.Provider>
  );
};

export default CreateMessage;
