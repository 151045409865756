import { createSlice } from '@reduxjs/toolkit';

import { Message } from '../../models/Message';

const initialState = {
  list: [] as Message[],
  loaded: false as boolean,
};

export type EditedMessagePayload = {
  id: string;
  body: string;
  bodyMarkdown: string;
};

const message = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    storeAllMessages(state, { payload }: { payload: Message[] }): void {
      if (payload.length === 0) {
        state.loaded = true;
        return;
      }
      if (!state.list.find((msg) => msg?._id === payload[0]?._id)) {
        payload.reverse().forEach((msg) => {
          state.list.unshift(msg);
        });
      }
    },
    addMessage(state, { payload }: { payload: Message }): void {
      const messageInState = state.list.find((msg) => msg._id === payload._id);
      if (messageInState && messageInState.needModeration !== payload.needModeration) {
        const newArray = [...state.list];
        newArray[newArray.indexOf(messageInState)].needModeration = payload.needModeration;
        newArray[newArray.indexOf(messageInState)].editedBody = payload.editedBody;
        newArray[newArray.indexOf(messageInState)].editedMarkdownBody = payload.editedMarkdownBody;
      }
      if (!messageInState) {
        state.list.push(payload);
      }
    },
    editMessage(state, { payload }: { payload: EditedMessagePayload }): void {
      const { id, body, bodyMarkdown } = payload;
      const index = state.list.findIndex((msg) => msg._id === id);
      const newArray = [...state.list];
      if (index !== -1 && newArray[index]) {
        newArray[index].isEdited = true;
        newArray[index].body = body;
        newArray[index].bodyMarkdown = bodyMarkdown;
        newArray[index].needModeration = false;
      }
    },
    questionUpdateMessage(state, { payload }: { payload: Message }): void {
      const index = state.list.findIndex((msg) => msg._id === payload._id);
      const newArray = [...state.list];
      if (index !== -1 && newArray[index]) {
        newArray[index].isQuestion = payload.isQuestion;
        newArray[index].isSelected = false;
      }
    },
    selectMessage(state, { payload }: { payload: string }): void {
      const index = state.list.findIndex((msg) => msg._id === payload);
      const newArray = [...state.list];
      newArray.forEach((msg) => (msg.isSelected = false));
      if (index !== -1 && newArray[index]) {
        newArray[index].isSelected = true;
      }
    },
    unselectMessage(state, { payload }: { payload: string }): void {
      const index = state.list.findIndex((msg) => msg._id === payload);
      const newArray = [...state.list];
      if (index !== -1 && newArray[index]) {
        newArray[index].isSelected = false;
      }
    },
    approveMessage(state, { payload }: { payload: string }): void {
      const index = state.list.findIndex((msg) => msg._id === payload);
      const newArray = [...state.list];
      if (index !== -1 && newArray[index]) {
        newArray[index].needModeration = false;
      }
    },
    deleteMessage(state, { payload }: { payload: string }): void {
      const index = state.list.findIndex((msg) => msg._id === payload);
      const newArray = [...state.list];
      if (index !== -1 && newArray[index]) {
        newArray[index].isQuestion = false;
        newArray[index].needModeration = false;
        newArray[index].deletedAt = new Date();
      }
    },
    updateMessageReactions(
      state,
      { payload }: { payload: { messageId: string; reactions: Message['reactions'] } }
    ): void {
      const index = state.list.findIndex((msg) => msg._id === payload.messageId);
      const newArray = [...state.list];
      if (index !== -1 && newArray[index]) {
        newArray[index].reactions = payload.reactions;
      }
    },
  },
});

export const {
  storeAllMessages,
  addMessage,
  editMessage,
  questionUpdateMessage,
  selectMessage,
  unselectMessage,
  approveMessage,
  deleteMessage,
  updateMessageReactions,
} = message.actions;
export default message.reducer;
