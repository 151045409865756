import { Message } from '../models/Message';
import moment from 'moment';
import { MouseEvent } from 'react';

// проверка на отправившего пользователя сообщение
export const isSameUserSendMessage = (messages: Message[], index: number): boolean =>
  messages[index].author._id === messages[index - 1]?.author?._id;

// склонение числительных
export const declination = (value: number, words: string[]): string => {
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
};

export const checkDateGroupMessage = (messages: Message[], index: number): string => {
  const currentDate = moment().format('ll');
  const yesterday = moment().subtract(1, 'days').format('ll');
  const messageDate = moment(messages[index].createdAt).format('ll').replace(' г.', '');
  const prevMessageDate = moment(messages[index - 1]?.createdAt).format('ll');

  if (messageDate === currentDate) {
    return 'Сегодня';
  }
  if (messageDate === yesterday) {
    return 'Вчера';
  }
  if (messageDate !== prevMessageDate) {
    return messageDate;
  }
  return prevMessageDate;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const throttle = (fn: Function, wait = 300) => {
  let inThrottle: boolean, lastFn: ReturnType<typeof setTimeout>, lastTime: number;
  return function (this: unknown) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this,
      // eslint-disable-next-line prefer-rest-params
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};
// скролл к закрепленному сообщению и подсветка
export const onPinnedMsgClick = (evt: MouseEvent, id: string): void => {
  const target = evt.target as HTMLElement;
  if (target.tagName !== 'svg' && target.tagName !== 'path' && target.tagName !== 'BUTTON') {
    const messages = document.querySelectorAll('.chat__item');
    const pinnedMessage = Array.from(messages).find((msg) => msg.id === id);
    pinnedMessage?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    pinnedMessage?.classList.add('chat__item--highlight');
    setTimeout(() => pinnedMessage?.classList.remove('chat__item--highlight'), 1000);
  }
  return;
};
