import React, { useContext } from 'react';
import { SocketServiceContext } from '../../services/SocketServiceContext';
import { BookmarkIcon } from '@primer/octicons-react';
import { Message } from '../../models/Message';
import { ClientQuestionSelectEvent, ClientQuestionUnselectEvent } from '../../services/MessageEvent';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import clsx from 'clsx';

export interface Props {
  message: Message;
}

export const SelectQuestionButton: React.FC<Props> = (props: Props) => {
  const { message } = props;
  const socketService = useContext(SocketServiceContext);
  const isSelected = useSelector((state: RootState) => state.messages.list).filter((msg) => msg._id === message._id)[0]
    .isSelected;

  const title = isSelected ? 'Отменить назначение главным вопросом' : 'Сделать главным вопросом';

  const onBtnClick = () => {
    !isSelected
      ? socketService?.emit(new ClientQuestionSelectEvent(message))
      : socketService?.emit(new ClientQuestionUnselectEvent(message));
  };

  return (
    <button
      className={clsx(
        'chat__inspect-button',
        'chat__inspect-button--bookmark',
        isSelected && 'chat__inspect-button--active'
      )}
      type="button"
      onClick={onBtnClick}
      title={title}
    >
      <BookmarkIcon />
      {title}
    </button>
  );
};
