import React from 'react';
import clsx from 'clsx';

export enum Color {
  GREEEN = 'green',
  BLUE = 'blue',
  YELLOW = 'yellow',
  PURPLE = 'purple',
  RED = 'red',
  ORANGE = 'orange',
}

export enum Size {
  SMALL = 'small',
  LARGE = 'large',
}

export interface BadgeElement {
  color?: Color;
  size?: Size;
  text: string | number;
}

// Кнопка скролла вниз
export const Badge: React.FC<BadgeElement> = (props: BadgeElement) => {
  const { color, text, size } = props;
  return (
    <span className={clsx('badge', color ? 'badge--' + color : '', size ? 'badge--' + size : '')}>
      <span className="badge__text">{text}</span>
    </span>
  );
};
