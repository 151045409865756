import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chat } from './Chat';
import { Tab } from '../constants/tabs';
import { Tabs } from '../components/Tabs';
import { Settings } from '../components/Settings';
import { Questions } from './Questions';
import { Moderation } from './Moderation';
import { Users } from './Users';
import { Rules } from './Rules';
import { ChatError } from '../components/ChatError';
import { Preloader } from '../components/Preloader';
import QuoteService from '../services/QuoteService';
import CreateMessageService from '../services/CreateMessageService';
import { RootState } from '../store';
import { changeActiveTab, changeSelectedQuestionsMode, changeStreamMode } from '../features/settings/settings.slice';
import { CurrentUserContext } from '../services/CurrentUserContext';
import { Q_CODE_KEY } from '../constants/constants';
import { CallCurator } from '../components/CallCurator';
import { PinnedMessage } from '../components/PinnedMessage';
import EditMessageService from '../services/EditMessageService';
import clsx from 'clsx';
import { CURATOR_EXIT_ROUTE } from './Root';
import { Slide, ToastContainer } from 'react-toastify';

export const App = (): ReactElement => {
  const dispatch = useDispatch();
  const [inIframe, setInIframeStatus] = useState(false);
  const {
    isChatLoading,
    isChatError,
    activeTab,
    isStreamMode,
    isSelectedQuestionsMode,
    room,
    pinnedMessage: pinnedMessageId,
  } = useSelector((state: RootState) => {
    return {
      isChatLoading: state.settings.isChatLoading,
      isChatError: state.settings.isChatError,
      activeTab: state.settings.activeTab,
      isStreamMode: state.settings.isStreamMode,
      isSelectedQuestionsMode: state.settings.isSelectedQuestionsMode,
      room: state.settings.room,
      pinnedMessage: state.settings.room.pinnedMessage,
    };
  });
  const pinnedMessage = useSelector((state: RootState) => state.messages.list).find(
    (msg) => msg._id === pinnedMessageId
  );
  const currentUser = useContext(CurrentUserContext);
  const isAdmin = currentUser?.canModerateMessages();
  const isDisabled = currentUser?.isBlocked() || currentUser?.isListener();
  const isExitCuratorWindow = window.location.pathname === CURATOR_EXIT_ROUTE;

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      if (event.ctrlKey && event.code === Q_CODE_KEY && isStreamMode) {
        dispatch(changeStreamMode(false));
        dispatch(changeSelectedQuestionsMode(false));
        dispatch(changeActiveTab(Tab.CHAT));
      }
    };
    document.addEventListener('keydown', handler);

    return () => document.removeEventListener('keydown', handler);
  });

  useEffect(() => {
    if (window.self !== window.top) {
      setInIframeStatus(true);
    }
  }, [window.self !== window.top]);

  useEffect(() => {
    document.querySelectorAll('.chat__text').forEach((node) => {
      const links = node.querySelectorAll('a');
      if (links) {
        links.forEach((link) => {
          link.target = '_parent';
        });
      }
    });
  });

  return (
    <Preloader isLoading={isChatLoading}>
      {isChatError ? (
        <ChatError />
      ) : (
        !isExitCuratorWindow && (
          <QuoteService>
            <EditMessageService>
              <CreateMessageService>
                <div
                  className={clsx(
                    'chat',
                    isStreamMode && isAdmin && 'chat--stream',
                    isSelectedQuestionsMode && isAdmin && 'chat--stream-question'
                  )}
                  {...(!inIframe && !isSelectedQuestionsMode && { style: { fontSize: '16px' } })}
                >
                  <header className="chat__header">
                    <Tabs />
                    {!isDisabled && <CallCurator />}
                    {isAdmin && room.settings?.hasStream && <Settings />}
                  </header>
                  <main className="chat__tab" aria-labelledby={activeTab}>
                    {pinnedMessage && activeTab === Tab.CHAT && <PinnedMessage message={pinnedMessage} />}
                    {activeTab === Tab.CHAT && <Chat />}
                    {activeTab === Tab.QUESTIONS && <Questions />}
                    {activeTab === Tab.MODERATION && <Moderation />}
                    {activeTab === Tab.USERS && <Users />}
                    {activeTab === Tab.RULES && <Rules />}
                  </main>
                </div>
                {!isStreamMode && <ToastContainer transition={Slide} />}
              </CreateMessageService>
            </EditMessageService>
          </QuoteService>
        )
      )}
    </Preloader>
  );
};
