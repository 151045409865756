import React, { useContext } from 'react';
import { QuoteServiceContext } from '../../services/QuoteService';
import { ReplyIcon } from '@primer/octicons-react';
import { Message } from '../../models/Message';
import { EditMessageServiceContext } from '../../services/EditMessageService';

export interface Props {
  message: Message;
}

export const ReplyButton: React.FC<Props> = (props: Props) => {
  const { message } = props;
  const quoteService = useContext(QuoteServiceContext);
  const editMessageService = useContext(EditMessageServiceContext);

  const onBtnClick = () => {
    editMessageService?.clearEditedMessage();
    quoteService?.setQuote(message);
    const textArea = document.querySelector('.field--text') as HTMLTextAreaElement;
    textArea?.focus();
  };

  return (
    <button className="chat__inspect-button" type="button" onClick={onBtnClick} title="Ответить">
      <ReplyIcon />
      Ответить
    </button>
  );
};
