/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import avatar1x from '../../assets/img/avatar@1x.jpg';
import avatar2x from '../../assets/img/avatar@2x.jpg';
import { MuteIcon } from '@primer/octicons-react';

import { Badge } from './Badge';
import clsx from 'clsx';

export interface UserElement {
  isBanned?: boolean;
  name: string;
  id: string;
  role: string;
  isOnline?: boolean;
}

export const User: React.FC<UserElement> = (props: UserElement) => {
  const { isBanned, name, id, role, isOnline } = props;
  return (
    <li className="chat__user-item">
      <img className="chat__avatar" src={avatar1x} srcSet={` ${avatar2x} 2x`} width="30" height="30" alt="" />
      <span className="chat__user">{name}</span>
      {isOnline ? (
        <span className="chat__user-online" title="Пользователь в сети">
          <i className="sr-only">Пользователь в сети</i>
        </span>
      ) : (
        ''
      )}
      {isBanned ? (
        <span className="chat__user-status">
          <MuteIcon verticalAlign="middle" />
        </span>
      ) : (
        ''
      )}
      <Badge text={`ID ${id}`} size="small" color="blue" />
      <span className="chat__user-role">{role}</span>
      <p className="chat__inspect">
        <button
          className={clsx(
            'chat__inspect-button',
            'chat__inspect-button--mute',
            isBanned && 'chat__inspect-button--active'
          )}
          type="button"
          title="Ответить"
        >
          <MuteIcon />
          {isBanned ? 'Разбанить' : 'Забанить'}
        </button>
      </p>
    </li>
  );
};
