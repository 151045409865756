import React from 'react';
import Twemoji from '../../assets/img/twemoji.svg';

type EmojiIcon = {
  id: string;
};

export const EmojiIcon: React.FC<EmojiIcon> = (props: EmojiIcon) => {
  const id = props.id.toLowerCase();
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20">
      <use href={`${Twemoji}#${id}`} />
    </svg>
  );
};
