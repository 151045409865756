import React from 'react';

import CloseIcon from '../../assets/img/components/icon-close--octicon.svg';

interface Props {
  name: string;
  children: React.ReactNode;
  closeModal?: () => void;
}

export const Modal: React.FC<Props> = (props: Props) => {
  const { name, children, closeModal } = props;

  return (
    <div className={`modal modal--${name}`} role="dialog">
      <div className="modal__inner">
        {closeModal && (
          <button className="modal__close" type="button" title="Закрыть" onClick={closeModal}>
            <CloseIcon />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};
