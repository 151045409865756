import React, { ReactElement } from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { InfoIcon, IssueClosedIcon, AlertIcon, StopIcon } from '@primer/octicons-react';

export const ToastifyMarkup = (): ReactElement => {
  const notify = () => toast('Deafault');
  const notifySuccess = () => toast.success('Success!', { icon: <IssueClosedIcon size="medium" /> });
  const notifyError = () => toast.error('Error!', { icon: <StopIcon size="medium" /> });
  const notifyWarn = () => toast.warn('Warning!', { icon: <AlertIcon size="medium" /> });
  const notifyInfo = () => toast.info('Info', { icon: <InfoIcon size="medium" /> });

  return (
    <>
      <div>
        <button onClick={notify}>Notify!</button>
        <button onClick={notifySuccess}>Notify Success!</button>
        <button onClick={notifyError}>Notify Error!</button>
        <button onClick={notifyWarn}>Notify Warn!</button>
        <button onClick={notifyInfo}>Notify Info!</button>
      </div>
      <ToastContainer transition={Slide} />
    </>
  );
};
