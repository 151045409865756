export enum UserRole {
  ADMIN = 'admin',
  STUDENT = 'student',
  TUTOR = 'tutor',
  AUTHOR = 'author',
  LISTENER = 'listener',
  CHECKER = 'checker',
  BOT = 'bot',
}

export enum TranslatedRole {
  'admin' = 'Модератор',
  'student' = 'Студент',
  'tutor' = 'Наставник',
  'author' = 'Автор',
  'listener' = 'Слушатель',
  'checker' = 'Проверяющий наставник',
  'bot' = 'Системный бот',
}
