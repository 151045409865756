import React from 'react';

// компоненты
import { Message as MessageInterface } from '../models/Message';
import { Scrollable } from '../components/Scrollable';
import { MessageList } from '../components/MessageList';
import { MessageForm } from '../components/MessageForm';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export const Questions: React.FC = () => {
  const questions = useSelector((state: RootState) => state.messages.list).filter(
    (msg: MessageInterface) => !msg.needModeration && msg.isQuestion && !msg.deletedAt
  );
  const highlightedQuestion = questions.find((msg) => msg.isSelected);
  const isSelectedQuestionsMode = useSelector((state: RootState) => state.settings.isSelectedQuestionsMode);

  const getQuestions = () => {
    if (isSelectedQuestionsMode) {
      if (highlightedQuestion) {
        return <MessageList messages={[highlightedQuestion]} />;
      }
      return <></>;
    }
    return <MessageList messages={questions} />;
  };

  return (
    <>
      <div className="chat__container">
        <Scrollable class="chat__list-wrapper chat__list-wrapper--content-start">
          <ul className="chat__list chat__list--questions">{getQuestions()}</ul>
        </Scrollable>
      </div>

      <MessageForm />
    </>
  );
};
