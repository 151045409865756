import React from 'react';
import { EmojiIcon } from './EmojiIcon';

type ModalReactionItem = {
  emojiId: string;
  onClickCallback: (emojiId: string) => void;
};

export const ModalReactionItem: React.FC<ModalReactionItem> = (props: ModalReactionItem) => {
  const emojiId = props.emojiId.toLowerCase();
  const onClickCallback = props.onClickCallback;
  return (
    <li className="chat__reaction-picker-item">
      <button type="button" className="chat__reaction-picker-button" onClick={() => onClickCallback(emojiId)}>
        <EmojiIcon id={emojiId} />
        <span className="sr-only"></span>
      </button>
    </li>
  );
};
