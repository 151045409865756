import React, { useContext } from 'react';
import { PinIcon } from '@primer/octicons-react';
import { Message as MessageInterface } from '../../models/Message';
import { SocketServiceContext } from '../../services/SocketServiceContext';
import { ClientMessageUnpinEvent, ClientMessagePinEvent } from '../../services/MessageEvent';
import clsx from 'clsx';

export interface Props {
  message: MessageInterface;
  isPinned: boolean;
}

export const PinnedStatusButton: React.FC<Props> = (props: Props) => {
  const { message, isPinned } = props;

  const socketService = useContext(SocketServiceContext);

  const onBtnClick = () => {
    socketService?.emit(isPinned ? new ClientMessageUnpinEvent(message) : new ClientMessagePinEvent(message));
  };

  const title = isPinned ? 'Открепить сообщение' : 'Закрепить сообщение';

  return (
    <button
      className={clsx(
        'chat__inspect-button',
        'chat__inspect-button--pinned',
        isPinned && 'chat__inspect-button--active'
      )}
      type="button"
      onClick={onBtnClick}
      title={title}
    >
      <PinIcon />
      {title}
    </button>
  );
};
