import React, { ReactElement, useState } from 'react';

import { CommentIcon, DeviceCameraVideoIcon, KebabHorizontalIcon } from '@primer/octicons-react';

export const MoreButtons = (): ReactElement => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const onMenuToggle = () => setMenuOpened(!menuOpened);

  return (
    <div className="chat__more-buttons-wrap">
      <button
        type="button"
        aria-label="Дополнительные настройки"
        aria-expanded={menuOpened}
        title="Дополнительные настройки"
        className="chat__more-button chat__more-button--toggle"
        onClick={onMenuToggle}
      >
        <KebabHorizontalIcon />
      </button>
      <ul className="chat__more-buttons-list">
        <li className="chat__more-buttons-item">
          <button type="button" title="Режим для стрима чата" className="chat__more-button chat__more-button--stream">
            <DeviceCameraVideoIcon /> Режим для стрима
          </button>
        </li>
        <li className="chat__more-buttons-item">
          <button
            type="button"
            title="Показать только вопросы"
            className="chat__more-button chat__more-button--questions"
          >
            <CommentIcon /> Только вопросы
          </button>
        </li>
      </ul>
    </div>
  );
};
