import React, { ReactElement } from 'react';

// img
import avatar1x from '../../assets/img/avatar@1x.jpg';
import avatar2x from '../../assets/img/avatar@2x.jpg';
import unknown_raccoon from '../../assets/img/unknown-raccoon.svg';
import { ReplyIcon, QuestionIcon, BookmarkIcon } from '@primer/octicons-react';

// компоненты
import { Tabs } from '../components-markup/Tabs';
import { MoreButtons } from '../components-markup/MoreButtons';
import { MessageFormSimple } from '../components-markup/MessageFormSimple';
import { ArrowDown } from '../components-markup/ArrowDown';

export const QuestionsMarkup = (): ReactElement => {
  const isPublic = true;
  const isMessageQuoted = true;

  return (
    <div className="chat" aria-label="Чат">
      <header className="chat__header">
        <Tabs {...{ isPublic }} />

        <MoreButtons />
      </header>

      <main className="chat__tab" aria-labelledby="questions">
        <div className="chat__container">
          <div className="scrollable">
            <div className="scrollable__wrapper" id="chat-window">
              <div className="scrollable__content chat__list-wrapper chat__list-wrapper--content-start">
                <ul className="chat__list chat__list--questions">
                  <li className="chat__item chat__item--avatar chat__item--question" tabIndex={0}>
                    <article className="chat__message">
                      <header className="chat__message-header">
                        <img
                          className="chat__avatar"
                          src={avatar1x}
                          srcSet={` ${avatar2x} 2x`}
                          width="30"
                          height="30"
                          alt=""
                        />
                        <span className="chat__user">Дельфина Дельфинская</span>
                      </header>
                      <div className="chat__text">
                        <p>Ваше сообщение</p>
                      </div>
                    </article>
                    <p className="chat__inspect">
                      <button className="chat__inspect-button" type="button" title="Ответить">
                        <ReplyIcon />
                        Ответить
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--question"
                        type="button"
                        title="Снять вопрос"
                      >
                        <QuestionIcon />
                        Cнять вопрос
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--bookmark"
                        type="button"
                        title="Сделать главным вопросом"
                      >
                        <BookmarkIcon />
                        Сделать главным вопросом
                      </button>
                    </p>
                  </li>

                  <li className="chat__item chat__item--question chat__item--selected" tabIndex={0}>
                    <article className="chat__message">
                      <header className="chat__message-header">
                        <span className="chat__user">Вениамин Вениаминович</span>
                      </header>
                      <div className="chat__text">
                        <p>
                          или ссылки <a href="http://cssgridgarden.com/">http://cssgridgarden.com/</a>
                        </p>
                      </div>
                    </article>
                    <p className="chat__inspect">
                      <button className="chat__inspect-button" type="button" title="Ответить">
                        <ReplyIcon />
                        Ответить
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--question"
                        type="button"
                        title="Снять вопрос"
                      >
                        <QuestionIcon />
                        Cнять вопрос
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--active chat__inspect-button--bookmark"
                        type="button"
                        title="Сделать главным вопросом"
                      >
                        <BookmarkIcon />
                        Отменить назначене главным вопросом
                      </button>
                    </p>
                  </li>
                  <li className="chat__item chat__item--avatar chat__item--question" tabIndex={0}>
                    <article className="chat__message">
                      <header className="chat__message-header">
                        <img className="chat__avatar" src={unknown_raccoon} width="30" height="30" alt="" />
                        <span className="chat__user chat__user--curator">admin</span>
                      </header>
                      <div className="chat__text">
                        <p>Ответ на вопрос пользователя</p>
                      </div>
                    </article>

                    <blockquote className="chat__quote">
                      <img
                        className="chat__avatar"
                        src={avatar1x}
                        srcSet={` ${avatar2x} 2x`}
                        width="30"
                        height="30"
                        alt=""
                      />
                      <span className="chat__user">Элеонора Элеоноровна</span>
                      <div className="chat__text">
                        <p>Вопрос пользователя?</p>
                        <p>
                          <i>курсив</i> <b>жирный</b> <em>выделение</em> <strong>усиление</strong> <span>спан </span>
                          <s>зачеркивание</s> <a href="#">ссылка</a>
                        </p>
                        <ul>
                          <li>Список</li>
                          <li>Список</li>
                        </ul>
                      </div>
                    </blockquote>

                    <p className="chat__inspect">
                      <button className="chat__inspect-button" type="button" title="Ответить">
                        <ReplyIcon />
                        Ответить
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--question"
                        type="button"
                        title="Снять вопрос"
                      >
                        <QuestionIcon />
                        Cнять вопрос
                      </button>
                      <button
                        className="chat__inspect-button chat__inspect-button--bookmark"
                        type="button"
                        title="Сделать главным вопросом"
                      >
                        <BookmarkIcon />
                        Сделать главным вопросом
                      </button>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <ArrowDown />
          </div>
        </div>

        <MessageFormSimple {...{ isMessageQuoted }} />
      </main>
    </div>
  );
};
