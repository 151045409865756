import React, { useContext, MouseEvent } from 'react';
import { throttle, onPinnedMsgClick } from '../helpers/utils';

import CloseIcon from '../assets/img/components/icon-close--octicon.svg';
import { PinIcon } from '@primer/octicons-react';
import { Message } from '../models/Message';
import { SocketServiceContext } from '../services/SocketServiceContext';
import { ClientMessageUnpinEvent } from '../services/MessageEvent';
import { CurrentUserContext } from '../services/CurrentUserContext';
export interface Props {
  message: Message;
}

const THROTTLE_INTERVAL = 1050;

export const PinnedMessage: React.FC<Props> = (props: Props) => {
  const { message } = props;
  const socketService = useContext(SocketServiceContext);
  const canCurrentUserModerate = useContext(CurrentUserContext)?.canModerateMessages();

  const onUnpinBtnClick = () => {
    socketService?.emit(new ClientMessageUnpinEvent(message));
  };

  return (
    <article
      className="chat__pinned"
      onClick={throttle((evt: MouseEvent) => onPinnedMsgClick(evt, message._id), THROTTLE_INTERVAL)}
    >
      <h2 className="chat__pinned-title">
        <PinIcon verticalAlign="unset" />
        Закрепленное сообщение
      </h2>
      {canCurrentUserModerate && (
        <button className="chat__close" type="button" title="Закрыть" onClick={onUnpinBtnClick}>
          <CloseIcon />
        </button>
      )}
      <blockquote
        className="chat__pinned-message chat__quote chat__text"
        dangerouslySetInnerHTML={{ __html: message.body }}
      />
    </article>
  );
};
