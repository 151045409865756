import { createSlice } from '@reduxjs/toolkit';
import { Tab } from '../../constants/tabs';
import { initialUser, User } from '../../models/User';
import { initialRoom, Room } from '../../models/Room';
import { TokenData } from '../../models/TokenData';
import { EditedMessagePayload } from '../messages/messages.slice';

export interface Settings {
  isChatLoading: boolean;
  isChatError: boolean;
  isChatClosed: boolean;
  isChatConnected: boolean;
  isChatReadOnly: boolean;
  typingUsers: number[];
  isUserActive: boolean;
  mode: string;
  title: string;
  activeTab: string;
  isArrowShow: boolean;
  user: User;
  room: Room;
  followBottom: boolean;
  isStreamMode: boolean;
  isSelectedQuestionsMode: boolean;
  isMessagesRequested: boolean;
}

const initialState: Settings = {
  isChatLoading: true,
  isChatError: false,
  isChatClosed: false,
  isChatConnected: true,
  isChatReadOnly: false,
  typingUsers: [],
  isUserActive: true,
  mode: 'private',
  title: '',
  activeTab: Tab.CHAT,
  isArrowShow: false,
  user: initialUser,
  room: initialRoom,
  followBottom: true,
  isStreamMode: false,
  isSelectedQuestionsMode: false,
  isMessagesRequested: false,
};

const message = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    storeTokenData(state, { payload }: { payload: TokenData }): void {
      state.room = payload.room;
      state.user = payload.user;
      state.user.mentionedUsers = [];
    },
    changeAppLoadingStatus(state, { payload }: { payload: boolean }): void {
      state.isChatLoading = payload;
    },
    changeAppErrorStatus(state, { payload }: { payload: boolean }): void {
      state.isChatError = payload;
    },
    changeCuratorCalledStatus(state, { payload }: { payload: boolean }): void {
      if (!state.room.settings.moderator) {
        state.room.settings.moderator = {};
      }
      state.room.settings.moderator.isCalled = payload;
    },
    changePageTitle(state, { payload }: { payload: string }): void {
      state.title = payload;
    },
    changeConnectedStatus(state, { payload }: { payload: boolean }): void {
      state.isChatConnected = payload;
    },
    changeChatCosedStatus(state, { payload }: { payload: boolean }): void {
      state.isChatClosed = payload;
    },
    changeRoomMode(state, { payload }: { payload: string }): void {
      state.mode = payload;
    },
    changeActiveTab(state, { payload }: { payload: string }): void {
      state.activeTab = payload;
    },
    setFollowBottom(state, { payload }: { payload: boolean }): void {
      state.followBottom = payload;
    },
    changeArrowShowingStatus(state, { payload }: { payload: boolean }): void {
      state.isArrowShow = payload;
    },
    changeBlockStatus(state, { payload }: { payload: boolean }): void {
      state.user.isBlocked = payload;
    },
    changeStreamMode(state, { payload }: { payload: boolean }): void {
      state.isStreamMode = payload;
    },
    changeSelectedQuestionsMode(state, { payload }: { payload: boolean }): void {
      state.isSelectedQuestionsMode = payload;
    },
    addMentionedUsers(state, { payload }: { payload: string }): void {
      // const wasMentioned = state.user.mentionedUsers?.find((usr) => usr === payload);
      // if (!wasMentioned) {
      state.user.mentionedUsers?.push(payload);
      // }
    },
    removeMentionedUsers(state): void {
      state.user.mentionedUsers = [];
    },
    setPinnedMessage(state, { payload }: { payload: string | undefined }): void {
      state.room.pinnedMessage = payload;
    },
    checkPinnedMessageEditing(state, { payload }: { payload: EditedMessagePayload }): void {
      const { id } = payload;
      if (id === state?.room?.pinnedMessage) {
        state.room.pinnedMessage = id;
      }
    },
    checkPinnedMessageDeleting(state, { payload }: { payload: string }): void {
      if (payload === state?.room?.pinnedMessage) {
        state.room.pinnedMessage = undefined;
      }
    },
    changeRequestedMessagesStatus(state, { payload }: { payload: boolean }): void {
      state.isMessagesRequested = payload;
    },
  },
});

export const {
  storeTokenData,
  changeAppLoadingStatus,
  changeAppErrorStatus,
  changeCuratorCalledStatus,
  changeConnectedStatus,
  changeActiveTab,
  setFollowBottom,
  changeArrowShowingStatus,
  changeBlockStatus,
  changeStreamMode,
  changeSelectedQuestionsMode,
  addMentionedUsers,
  removeMentionedUsers,
  setPinnedMessage,
  checkPinnedMessageEditing,
  checkPinnedMessageDeleting,
  changeRequestedMessagesStatus,
} = message.actions;
export default message.reducer;
