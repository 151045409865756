import React, { ChangeEvent, useState, useContext, useEffect, useRef } from 'react';

import { Modal } from '../modals/Modal';
import { EmojiIcon } from './EmojiIcon';

import useOnClickOutside from 'use-onclickoutside';
import { ESC_KEY } from '../../constants/constants';

import { Emoji, emojis } from './emojis';
import { BotReactionsData, EmojiData } from '../../models/ReactionData';
import { ReactionServiceContext } from '../../services/ReactionContext';
import { SocketServiceContext } from '../../services/SocketServiceContext';
import { ClientReactionBotEvent } from '../../services/ReactionEvent';

export const ModalReactionsBot: React.FC = () => {
  const [emojiData, setEmojiData] = useState<EmojiData>({});
  const socketService = useContext(SocketServiceContext);
  const reactionService = useContext(ReactionServiceContext);

  const isModalOpened = Boolean(reactionService?.botMessageId);
  const targetMessageid = reactionService?.botMessageId;

  const ref = useRef<HTMLDivElement>(null);

  const closeModal = () => {
    reactionService?.clearBotMessageId();
  };

  // Закрываем по клику по оверлею
  useOnClickOutside(ref, () => closeModal());

  // Закрываем по нажатию esc
  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      if (event.key === ESC_KEY) {
        closeModal();
      }
    };
    document.addEventListener('keydown', handler);

    return () => document.removeEventListener('keydown', handler);
  });

  const onClickCallback = () => {
    if (targetMessageid) {
      const reactions: BotReactionsData = {
        messageId: targetMessageid,
        reactions: emojiData,
      };
      socketService?.emit(new ClientReactionBotEvent(reactions));
      setEmojiData({});
      closeModal();
    }
  };

  const onChangeCountCallback = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(evt.target.value, 10);
    if (evt.target.dataset.emoji) {
      emojiData[evt.target.dataset.emoji] = value;
    }
  };

  return (
    <>
      {isModalOpened && (
        <Modal name="multi-reactions" closeModal={closeModal}>
          <div className="modal__wrapper">
            <h2 className="modal__title">Reaction-бот</h2>
            <ul className="chat__reaction-picker-list chat__reaction-picker-list--reactions-bot">
              {emojis.map((emoji: Emoji, i: number) => (
                <li key={i} className="chat__reaction-picker-item">
                  <EmojiIcon id={emoji.id} />
                  <input
                    type="number"
                    className="field field--text field--emoji"
                    data-emoji={emoji.id.toLowerCase()}
                    min="0"
                    onChange={onChangeCountCallback}
                  />
                </li>
              ))}
            </ul>
            <div className="modal__buttons ">
              <button className="modal__button button button--wide" onClick={onClickCallback}>
                Запустить
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
